// react

// local
import { TimeSubmission } from "../../../../../../../interfaces/TimeSubmission";

// thirdparty
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { get } from "lodash";
import ClearIcon from "@mui/icons-material/Clear";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Field } from "react-final-form";

interface DailyEditDialogTimeEntryProps {
  values: Record<string, unknown>;
  remove: (name: string, index: number) => void;
  weekDay: Date;
}

const DailyEditDialogTimeEntry = ({
  remove,
  values,
  weekDay,
}: DailyEditDialogTimeEntryProps) => {
  const items = get(values, "timeSubmissions") as TimeSubmission[];
  console.log(items);

  // Helpers
  const removeSubmissionDateTime = (indexToRemove: number) => {
    remove("timeSubmissions", indexToRemove);
  };

  const handleDateChange = (
    value: Date | null,
    onChange: (value: Date | null) => void
  ) => {
    if (value) {
      const hours = new Date(value).getHours();
      const minutes = new Date(value).getMinutes();
      const newDate = new Date(
        new Date(new Date(weekDay).setHours(hours)).setMinutes(minutes)
      );
      console.log(newDate);
      onChange(newDate);
    } else {
      onChange(value);
    }
  };

  if (items.length === 0) {
    return (
      <Grid item xs={12} sx={{ width: "100%", height: 300 }}>
        <Typography textAlign="center">
          No time entries available for this date.
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item sx={{ width: "100%", height: 300 }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            itemCount={items.length}
            itemSize={75}
          >
            {({ style, index }) => (
              <Grid
                style={style}
                item
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                key={`grid-item-${index}`}
              >
                <Grid item xs={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Field
                      name={`timeSubmissions[${index}].submission_datetime`}
                    >
                      {({ input: { value, onChange } }) => (
                        <TimePicker
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="standard"
                            />
                          )}
                          value={value}
                          onChange={(value) =>
                            handleDateChange(value, onChange)
                          }
                        />
                      )}
                    </Field>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <Typography>
                    {
                      get(
                        values,
                        `timeSubmissions[${index}].submission_type`
                      ) as string
                    }
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {index ===
                  (values.timeSubmissions as TimeSubmission[]).length - 1 ? (
                    <Tooltip title="Remove Time Submission">
                      <IconButton
                        onClick={() => removeSubmissionDateTime(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            )}
          </List>
        )}
      </AutoSizer>
    </Grid>
  );
};

export default DailyEditDialogTimeEntry;
