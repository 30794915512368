// react
import React from "react";

// local

// thirdparty
import { InputAdornment } from "@mui/material";
import { TextField, TextFieldProps } from "mui-rff";
import EmailIcon from "@mui/icons-material/Email";

interface Props extends TextFieldProps {}

const EmailField = (props: Props) => {
  return (
    <TextField
      {...props}
      type="email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

EmailField.defaultProps = {
  name: "email",
  label: "Email",
  variant: "standard",
};

export default EmailField;
