// local

// thirdparty
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PathState {
  path: string | null;
  passcode: string | null;
}

const initialState: PathState = {
  path: null,
  passcode: null,
};

export const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {
    setPath: (state, action: PayloadAction<string | null>) => {
      state.path = action.payload;
    },
    setPasscode: (state, action: PayloadAction<string | null>) => {
      state.passcode = action.payload;
    },
    setPathState: (state, action: PayloadAction<PathState>) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { reducer: pathReducer } = pathSlice;
export const { setPath, setPasscode, setPathState } = pathSlice.actions;
