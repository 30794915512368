// react
import React, { useRef, useState } from "react";

// local
import UserService from "../../../services/UserService";
import { User } from "../../../interfaces/User";
import PinEdit from "../../public/Shared/PinEdit";
import UserFormDialog from "./UserFormDialog";
import { PageResponse } from "../../../interfaces/Response";
import PasswordEdit from "../../public/Shared/PasswordEdit";
import { useLoggedInUser } from "../../../reduxConfig/reduxHooks";

// thirdparty
import { Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import PinIcon from "@mui/icons-material/Pin";
import MaterialTable, { Query, QueryResult } from "@material-table/core";

const Users = () => {
  // State
  const [pinEditDialogOpen, setPinEditDialogOpen] = useState<boolean>(false);
  const [passwordEditDialogOpen, setPasswordEditDialogOpen] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [userEditOpenDialog, setUserEditOpenDialog] = useState<boolean>(false);

  // Redux
  const authenticatedUser = useLoggedInUser();

  // Refs
  const tableRef = useRef<any>();

  // Helpers
  const pullUserData = (query: Query<User>): Promise<QueryResult<User>> => {
    return new Promise((resolve, reject) => {
      UserService.getInstance()
        .User.list<PageResponse<User>>({
          args: [],
          queryParams: {
            page: (query.page + 1).toString(),
            pageSize: query.pageSize.toString(),
            search: query.search,
            ordering: query.orderBy
              ? `${query.orderDirection === "asc" ? "" : "-"}${
                  query.orderBy.field
                }`
              : undefined,
          },
        })
        .then((response) => {
          const { count, results } = response.data;
          resolve({
            data: results,
            page: query.page,
            totalCount: count,
          });
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const deleteUser = (user: User) => {
    UserService.getInstance()
      .delete({
        args: [user.uuid],
      })
      .then((response) => {
        refreshTable();
      });
  };

  const refreshTable = () => {
    tableRef.current?.onQueryChange();
  };

  return (
    <>
      <Container>
        <MaterialTable
          tableRef={tableRef}
          actions={[
            {
              icon: PinIcon,
              onClick: (_e, rowData) => {
                setSelectedUser(rowData as User);
                setPinEditDialogOpen(true);
              },
              tooltip: "Override User Passcode",
            },
            {
              icon: PasswordIcon,
              onClick: (_e, rowData) => {
                setPasswordEditDialogOpen(true);
                setSelectedUser(rowData as User);
              },
              tooltip: "Override User Password",
            },
            {
              icon: EditIcon,
              onClick: (_e, rowData) => {
                setSelectedUser(rowData as User);
                setUserEditOpenDialog(true);
              },
              tooltip: "Edit User",
            },
            (rowData) => ({
              icon: DeleteIcon,
              onClick: () => {
                deleteUser(rowData);
              },
              tooltip: "Deactivate User",
              hidden: rowData.uuid === authenticatedUser?.uuid,
            }),
            {
              icon: AddIcon,
              isFreeAction: true,
              onClick: (e) => {
                setUserEditOpenDialog(true);
              },
              tooltip: "Add User",
            },
          ]}
          components={{
            Actions: (props) => {
              const {
                actions,
                components,
                data,
                size,
                disabled,
                forwardedRef,
              } = props;

              if (!actions) {
                return null;
              }
              return (
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  ref={forwardedRef}
                >
                  {actions.map((action: any, index: number) => (
                    <components.Action
                      action={action}
                      key={"action-" + index}
                      data={data}
                      size={size}
                      disabled={disabled}
                    />
                  ))}
                </div>
              );
            },
          }}
          columns={[
            { title: "Name", field: "name", width: 300 },
            { title: "Email", field: "email", width: 300 },
            {
              title: "Role",
              field: "role",
              lookup: { 1: "Administrator", 0: "User" },
            },
          ]}
          data={pullUserData}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            pageSize: 10,
            pageSizeOptions: [5, 10],
            searchFieldAlignment: "left",
            showTitle: false,
            paginationType: "stepped",
          }}
        />
      </Container>
      <PinEdit
        open={pinEditDialogOpen}
        closeDialog={() => {
          refreshTable();
          setPinEditDialogOpen(false);
          setSelectedUser(undefined);
        }}
        userGuid={selectedUser?.uuid}
      />
      <PasswordEdit
        open={passwordEditDialogOpen}
        closeDialog={() => {
          refreshTable();
          setPasswordEditDialogOpen(false);
          setSelectedUser(undefined);
        }}
        userGuid={selectedUser?.uuid}
      />
      <UserFormDialog
        open={userEditOpenDialog}
        closeDialog={() => {
          refreshTable();
          setUserEditOpenDialog(false);
          setSelectedUser(undefined);
        }}
        selectedUser={selectedUser}
      />
    </>
  );
};
export default Users;
