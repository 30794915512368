// local
import CreateModelEndpoint from "./CreateModelEndpoint";
import ListModelEndpoint from "./ListModelEndpoint";
import RetrieveModelEndpoint from "./RetrieveModelEndpoint";
import UpdateModelEndpoint from "./UpdateModelEndpoint";
import DestroyModelEndpoint from "./DestroyModelEndpoint";
import BaseModelEndpoint from "./BaseModelEndpoint";

// thirdparty
import { Mixin } from "ts-mixer";

export default class ModelEndpoint extends Mixin(
  CreateModelEndpoint,
  ListModelEndpoint,
  RetrieveModelEndpoint,
  UpdateModelEndpoint,
  DestroyModelEndpoint,
  BaseModelEndpoint
) {}
