// react
import React, { useEffect, useMemo, useState } from "react";

// local
import { getNDayOfWeek } from "../../../../helpers/dateHelpers";
import GeneratedDay from "../../../private/Home/SubmissionWeeklySummary/GeneratedDay";
import { generateWeek } from "../../../private/Home/SubmissionWeeklySummary/helpers";
import { TimeSubmission } from "../../../../interfaces/TimeSubmission";
import TimeSubmissionService from "../../../../services/TimeSubmissionService";
import { UUID } from "../../../../types";

// thirdparty
import { Grid } from "@mui/material";

interface Props {
  selectedDate: Date;
  userGuid: UUID | null;
  refresh?: () => void;
}

const WeeklySummary: React.FC<Props> = ({
  selectedDate,
  userGuid,
  refresh,
}) => {
  // State
  const [timeSubmissions, setTimeSubmissions] = useState<TimeSubmission[]>([]);

  // Memoized data
  const sunday = useMemo(() => getNDayOfWeek(0, selectedDate), [selectedDate]);
  const saturday = useMemo(
    () =>
      new Date(
        new Date(getNDayOfWeek(6, selectedDate)).setHours(23, 59, 59, 99)
      ),
    [selectedDate]
  );

  // Helpers
  const refreshData = (userGuid: string) => {
    TimeSubmissionService.getInstance()
      .Submissions.list<TimeSubmission[]>({
        args: [userGuid],
        queryParams: {
          date_after: sunday.toISOString(),
          date_before: saturday.toISOString(),
        },
      })
      .then((response) => {
        setTimeSubmissions(response.data);
      });
  };

  const requestRefresh = () => {
    refresh?.();
    refreshData(userGuid as UUID);
  };

  // Use-Effects
  useEffect(() => {
    if (userGuid) refreshData(userGuid);
  }, [userGuid, sunday, saturday]);

  return (
    <>
      <Grid container columnSpacing={1} justifyContent="center">
        {generateWeek(selectedDate, 0, 3).map((weekDay, index) => (
          <GeneratedDay
            key={index}
            weekDay={weekDay}
            timeSubmissions={timeSubmissions}
            userGuid={userGuid}
            refresh={requestRefresh}
          />
        ))}
      </Grid>
      <Grid container columnSpacing={1} justifyContent="center">
        {generateWeek(selectedDate, 4, 6).map((weekDay, index) => (
          <GeneratedDay
            key={index}
            weekDay={weekDay}
            userGuid={userGuid}
            timeSubmissions={timeSubmissions}
            refresh={requestRefresh}
          />
        ))}
      </Grid>
    </>
  );
};
WeeklySummary.defaultProps = {
  userGuid: null,
};

export default WeeklySummary;
