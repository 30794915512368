// local

// thirdparty
import { createTheme } from "@mui/material/styles";

export const blueTheme = createTheme({
  palette: {
    primary: {
      main: "#1565c0",
    },
    secondary: {
      main: "#d32f2f",
    },
  },
});

export const redTheme = createTheme({
  palette: {
    primary: {
      main: "#b00233",
    },
    secondary: {
      main: "#fee608",
    },
  },
});

let theme = blueTheme;
if (window.location.origin.includes("riverdale")) {
  theme = redTheme;
}

export { theme };
