// local
import { Response } from "../interfaces/Response";
import {
  ListModelEndpoint,
  UpdateModelEndpoint,
  DestroyModelEndpoint,
} from "./ModelEndpoint";
import BaseModelEndpoint from "./ModelEndpoint/BaseModelEndpoint";
import { UUID } from "../types";
import { TimeSubmission } from "../interfaces/TimeSubmission";

// thirdparty
import axios from "axios";
import { Mixin } from "ts-mixer";

class ModelUpdateEndpoint extends Mixin(
  ListModelEndpoint,
  UpdateModelEndpoint,
  DestroyModelEndpoint,
  BaseModelEndpoint
) {}

export default class TimeSubmissionService {
  private static _instance: TimeSubmissionService;
  public Submissions: ModelUpdateEndpoint;

  static getInstance(): TimeSubmissionService {
    if (!TimeSubmissionService._instance) {
      TimeSubmissionService._instance = new TimeSubmissionService();
    }
    return TimeSubmissionService._instance;
  }

  public constructor() {
    this.Submissions = new ModelUpdateEndpoint(
      "/api/v1/users/:userId/submission/"
    );
  }

  public retrieve(userGuid: UUID): Promise<Response<TimeSubmission>> {
    return axios.get(`/api/v1/users/${userGuid}`);
  }
}
