// react
import { forwardRef, useMemo } from "react";

// local
import { AllowedIP } from "../../../interfaces/AllowedIP";
import IPAddressInput from "../../public/Shared/IPAddressInput";
import AllowedIPService from "../../../services/AllowedIPService";
import { PageResponse } from "../../../interfaces/Response";
import { raiseAlert } from "../../../helpers/alertHelpers";
import { utcDateTimeFormatter } from "../../../helpers/dateHelpers";

// thirdparty
import MaterialTable, {
  Column,
  Query,
  QueryResult,
  Icons,
} from "@material-table/core";
import { Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AllowedIPList = () => {
  // Memoized Data
  const columns: Column<AllowedIP>[] = useMemo(
    () => [
      {
        field: "created",
        title: "Created",
        editable: "never",
        width: 250,
        render: (rowData: AllowedIP) => utcDateTimeFormatter(rowData.created),
      },
      {
        field: "allowed_ip",
        title: "Allowed IP",
        width: 350,
        editComponent: (props) => <IPAddressInput {...props} />,
        initialEditValue: "....",
      },
      {
        field: "description",
        title: "Description",
      },
    ],
    []
  );

  // Service
  const allowedIPService = AllowedIPService.getInstance();

  // Helpers
  const fetchAllowedIPAddresses = (query: Query<AllowedIP>) => {
    return new Promise<QueryResult<AllowedIP>>((resolve, reject) => {
      allowedIPService.AllowedIP.list<PageResponse<AllowedIP>>({
        args: [],
        queryParams: {
          pageSize: query.pageSize.toString(),
          page: (query.page + 1).toString(),
          search: query.search,
          ordering: query.orderBy
            ? `${query.orderDirection === "asc" ? "" : "-"}${
                query.orderBy.field
              }`
            : undefined,
        },
      })
        .then((response) => {
          const { results, count } = response.data;
          resolve({
            data: results,
            totalCount: count,
            page: query.page,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const handleNewRowCreation = (newData: AllowedIP) => {
    return new Promise<void>((resolve, reject) => {
      allowedIPService.AllowedIP.create({ args: [], newEntity: newData })
        .then(() => {
          raiseAlert("The new IP address was created successfully.");
          resolve();
        })
        .catch(() => {
          raiseAlert(
            "There was an error creating the new IP address.",
            "error"
          );
          reject();
        });
    });
  };

  const handleExistingRowEdit = (newData: AllowedIP, oldData?: AllowedIP) => {
    if (oldData) {
      return new Promise<void>((resolve, reject) => {
        allowedIPService.AllowedIP.update({
          args: [oldData.uuid],
          updatedEntity: newData,
        })
          .then(() => {
            raiseAlert("The requested IP address was updated successfully.");
            resolve();
          })
          .catch(() => {
            raiseAlert(
              "There was an error updating the requested IP address.",
              "error"
            );
            reject();
          });
      });
    } else {
      return Promise.reject();
    }
  };

  const handleRowDeletion = (oldData: AllowedIP) => {
    return new Promise<void>((resolve, reject) => {
      allowedIPService.AllowedIP.destroy({ args: [oldData.uuid] })
        .then(() => {
          raiseAlert("IP address deleted successfully.");
          resolve();
        })
        .catch(() => {
          raiseAlert(
            "There was an error deleting the requested IP address.",
            "error"
          );
          reject();
        });
    });
  };

  // Memoized data
  const icons: Icons<AllowedIP> = useMemo(
    () => ({
      Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    }),
    []
  );

  return (
    <Container>
      <MaterialTable
        columns={columns}
        data={fetchAllowedIPAddresses}
        editable={{
          onRowAdd: handleNewRowCreation,
          onRowUpdate: handleExistingRowEdit,
          onRowDelete: handleRowDeletion,
        }}
        icons={icons}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
          pageSize: 10,
          pageSizeOptions: [5, 10],
          searchFieldAlignment: "left",
          showTitle: false,
          paginationType: "stepped",
        }}
      />
    </Container>
  );
};

export default AllowedIPList;
