// react

import { useEffect } from "react";

// local
import UserService from "../../../services/UserService";
import { URLMethods } from "../../../services/ModelEndpoint";
import { User } from "../../../interfaces/User";
import { useAppDispatch } from "../../../reduxConfig/reduxHooks";
import {
  setAuthenticated,
  setUser,
} from "../../../reduxConfig/slices/authSlice";
import { raiseAlert } from "../../../helpers/alertHelpers";

// thirdparty
import { useNavigate } from "react-router";

const CompleteRemoteLogin = () => {
  // Redux
  const dispatch = useAppDispatch();

  // React router
  const navigate = useNavigate();

  // Side-Effects
  useEffect(() => {
    UserService.getInstance()
      .User.action<unknown, User>({
        args: [],
        actionURL: "/api/v1/authentication/complete-login/",
        method: URLMethods.PATCH,
      })
      .then((response) => {
        dispatch(setUser(response.data));
        dispatch(setAuthenticated(true));
        navigate("/home");
      })
      .catch((err) => {
        raiseAlert(
          "We were unable to complete your login. Please try again.",
          "error"
        );
        navigate("/login");
      });
  }, []);

  return <></>;
};

export default CompleteRemoteLogin;
