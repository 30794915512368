// react

// local
import { routes } from "../../../configuration/routes";
import Login from "../authentication/Login";
import PrivateRoute from "./PrivateRoutes";
import Redirect from "./Redirect";

// thirdparty
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CompleteRemoteLogin from "../authentication/CompleteRemoteLogin";
import UpdatePassword from "../authentication/UpdatePassword";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="update-password" element={<UpdatePassword />} />
        <Route path="complete-login" element={<CompleteRemoteLogin />} />
        {routes.map((route, index) => {
          if (route.subRoutes && route.subRoutes.length >= 1) {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.component ? (
                    <PrivateRoute
                      component={route.component}
                      permissions={route.permissions}
                      title={route.title}
                    />
                  ) : undefined
                }
              >
                {route.subRoutes.map((subRoute) => (
                  <Route
                    key={`${route.path}-${subRoute.path}`}
                    path={subRoute.path}
                    element={
                      subRoute.component ? (
                        <PrivateRoute
                          component={subRoute.component}
                          permissions={route.permissions}
                          title={subRoute.title}
                        />
                      ) : undefined
                    }
                  />
                ))}
              </Route>
            );
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={
                route.component ? (
                  <PrivateRoute
                    component={route.component}
                    permissions={route.permissions}
                    title={route.title}
                  />
                ) : undefined
              }
            />
          );
        })}
        <Route path="*" element={<Redirect path="home" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
