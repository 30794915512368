// react
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// local
import { getNDayOfWeek } from "../../../../helpers/dateHelpers";
import WeekPicker from "./WeekPicker/WeekPicker";

// thirdparty
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Popover,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface Props {
  initialSelection: Date;
  onChange: (newDate: Date) => void;
  ButtonGroupProps: ButtonGroupProps;
  includeWeekends?: boolean;
}

const DateSelection = ({
  initialSelection,
  onChange,
  ButtonGroupProps,
  includeWeekends,
}: Props) => {
  // State
  const [popOverOpen, setPopOverOpen] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  // Ref
  const iconButtonRef = useRef<any>();

  // Memoized data
  const previousWeek = useMemo(
    () =>
      new Date(
        new Date(initialSelection.toISOString()).setDate(
          new Date(initialSelection.toISOString()).getDate() - 7
        )
      ),
    [initialSelection]
  );

  const nextWeek = useMemo(
    () =>
      new Date(
        new Date(initialSelection.toISOString()).setDate(
          new Date(initialSelection.toISOString()).getDate() + 7
        )
      ),
    [initialSelection]
  );

  const weekButton = useMemo(() => {
    if (windowWidth >= 450) {
      return `${getNDayOfWeek(
        includeWeekends ? 0 : 1,
        initialSelection
      ).toLocaleDateString()} - ${getNDayOfWeek(
        includeWeekends ? 6 : 5,
        initialSelection
      ).toLocaleDateString()}`;
    } else {
      return <DateRangeIcon />;
    }
  }, [windowWidth, initialSelection, includeWeekends]);

  // Helpers
  const resizeEvent = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  // Side-Effects
  useEffect(() => {
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [resizeEvent]);

  return (
    <>
      <ButtonGroup {...ButtonGroupProps}>
        <Tooltip title={previousWeek.toLocaleDateString()}>
          <Button onClick={() => onChange(previousWeek)}>
            <ArrowBackIcon />
          </Button>
        </Tooltip>
        <Button ref={iconButtonRef} onClick={() => setPopOverOpen(true)}>
          {weekButton}
        </Button>
        <Tooltip title={nextWeek.toLocaleDateString()}>
          <Button onClick={() => onChange(nextWeek)}>
            <ArrowForwardIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Popover
        anchorEl={iconButtonRef.current}
        open={popOverOpen}
        onClose={() => setPopOverOpen(false)}
      >
        <WeekPicker
          initialSelection={new Date(initialSelection.toISOString())}
          onChange={(newDate) => {
            setPopOverOpen(false);
            onChange(newDate);
          }}
          includeWeekends={includeWeekends}
        />
        <Box
          sx={{
            p: 1,
            textAlign: "right",
          }}
        >
          <Button onClick={() => setPopOverOpen(false)}>Cancel</Button>
        </Box>
      </Popover>
    </>
  );
};

DateSelection.defaultProps = {
  ButtonGroupProps: {
    variant: "outlined",
    size: "small",
  },
};

export default DateSelection;
