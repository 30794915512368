// react
import React from "react";

// local
import { useAppDispatch } from "../../../reduxConfig/reduxHooks";
import { forceLogout } from "../../../reduxConfig/slices/authSlice";
import { setPathState } from "../../../reduxConfig/slices/pathSlice";

// thirdparty
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ProfileButton = () => {
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Redux
  const dispatch = useAppDispatch();

  // React Router
  const navigate = useNavigate();

  // Helpers
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    handleClose();
    dispatch(forceLogout());
    dispatch(setPathState({ path: null, passcode: null }));
    navigate("/login");
  };

  const openProfile = () => {
    navigate("/profile");
    handleClose();
  };

  return (
    <Box>
      <IconButton color="inherit" onClick={handleMenu}>
        <AccountCircleIcon color="inherit" fontSize="large" />
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        sx={{
          mt: "45px",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openProfile}>My Profile</MenuItem>
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileButton;
