// react
import React, { useEffect, useState } from "react";

// local
import { UUID } from "../../../../types";
import { validate } from "../../../../helpers/generalHelpers";
import PasswordField from "../Forms/ReactFinalForms/PasswordField";
import UserService from "../../../../services/UserService";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { URLMethods, URLTypes } from "../../../../services/ModelEndpoint";

interface Props {
  open: boolean;
  closeDialog: () => void;
  userGuid?: UUID;
}

const PasswordEdit: React.FC<Props> = ({ open, closeDialog, userGuid }) => {
  const [key, setKey] = useState<UUID>(uuidv4());

  // Helpers
  const handleSubmit = (values: Record<string, string>) => {
    UserService.getInstance()
      .User.action({
        args: [userGuid as UUID, ""],
        actionURL: "/api/v1/users/:userGuid/override-user-password/",
        urlType: URLTypes.LIST,
        method: URLMethods.PUT,
        submissionData: values,
      })
      .then(() => {
        closeDialog();
      })
      .catch(() => {
        console.log("There was an error");
      });
  };

  const generateSchema = () => {
    return Yup.object().shape({
      password: Yup.string().nullable().required("A password is required."),
    });
  };

  // Side-Effects
  useEffect(() => {
    if (!open) {
      setKey(uuidv4());
    }
  }, [open]);

  return (
    <Form
      key={key}
      onSubmit={handleSubmit}
      validate={(values: Record<string, string>) =>
        validate(values, generateSchema())
      }
    >
      {({ handleSubmit }) => (
        <Dialog open={open} maxWidth="xs" fullWidth>
          <DialogTitle>Update Password</DialogTitle>
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <PasswordField name="password" label="Password" />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button type="submit">Update Password</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Form>
  );
};

export default PasswordEdit;
