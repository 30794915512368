// react
import React from "react";

// local
import {
  SubmissionTypes,
  TimeSubmission,
} from "../../../../../../interfaces/TimeSubmission";
import TimeSubmissionService from "../../../../../../services/TimeSubmissionService";
import { useLoggedInUser } from "../../../../../../reduxConfig/reduxHooks";
import { User } from "../../../../../../interfaces/User";
import { URLMethods } from "../../../../../../services/ModelEndpoint";
import { raiseAlert } from "../../../../../../helpers/alertHelpers";
import DailyEditDialogTimeEntry from "./DailyEditDialogTimeEntry";
import { UUID } from "../../../../../../types";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Form } from "react-final-form";
import { last } from "lodash";
import { orderBy } from "lodash";
import arrayMutators from "final-form-arrays";

interface DailyEditDialogProps {
  open: boolean;
  onClose: () => void;
  timeSubmissions: TimeSubmission[];
  userGuid: UUID | null;
  weekDay: Date;
}

const DailyEditDialog = (props: DailyEditDialogProps) => {
  // Expand props
  const { open, onClose, timeSubmissions, userGuid, weekDay } = props;

  console.log(weekDay);

  // Service
  const timeSubmissionService = TimeSubmissionService.getInstance();

  // Redux
  const activeUser: User = useLoggedInUser() as User;

  // Helpers
  const handleSubmitData = (values: Record<string, unknown>) => {
    timeSubmissionService.Submissions.action({
      actionURL: "/api/v1/users/:userGuid/submission/bulk-update-submissions/",
      queryParams: {
        date: weekDay.toISOString(),
      },
      args: [userGuid ? userGuid : activeUser.uuid],
      method: URLMethods.PUT,
      submissionData: values,
    })
      .then(() => {
        onClose();
        raiseAlert(
          `Submissions have been updated for ${new Intl.DateTimeFormat(
            "en-US",
            { weekday: "short" }
          ).format(weekDay)} ${weekDay.toLocaleDateString()}.`
        );
      })
      .catch(() => {
        raiseAlert(
          `There was an issue updating the submissions for ${new Intl.DateTimeFormat(
            "en-US",
            { weekday: "short" }
          ).format(weekDay)} 
      ${weekDay.toLocaleDateString()}.`,
          "error"
        );
      });
  };

  const handleDialogClose = (
    e: React.MouseEvent | React.KeyboardEvent,
    reason: "escapeKeyDown" | "backdropClick"
  ) => {
    if (reason === "escapeKeyDown") {
      onClose();
    }
  };

  const determineNewSubmissionType = (submissionType: SubmissionTypes) => {
    return submissionType === SubmissionTypes.CHECK_IN
      ? SubmissionTypes.CHECK_OUT
      : SubmissionTypes.CHECK_IN;
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
      <Form
        onSubmit={handleSubmitData}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          timeSubmissions: orderBy(timeSubmissions, ["submission_datetime"]),
        }}
        subscription={{ values: true }}
      >
        {({
          handleSubmit,
          form: {
            mutators: { push, remove },
          },
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6">Update Time Submissions</Typography>
                <Button
                  onClick={() => {
                    push("timeSubmissions", {
                      submission_datetime: new Date(
                        new Date(
                          new Date(weekDay).setHours(new Date().getHours())
                        ).setMinutes(new Date().getMinutes())
                      ),
                      submission_type: determineNewSubmissionType(
                        last(values.timeSubmissions as TimeSubmission[])
                          ?.submission_type ?? SubmissionTypes.CHECK_OUT
                      ),
                    });
                  }}
                >
                  Add New Time
                </Button>
              </Stack>
              <Grid container spacing={2}>
                <DailyEditDialogTimeEntry
                  remove={remove}
                  values={values}
                  weekDay={weekDay}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default DailyEditDialog;
