// local
import { Response } from "../../interfaces/Response";
import BaseModelEndpoint from "./BaseModelEndpoint";

// thirdparty
import axios from "axios";

export default class CreateModelEndpoint extends BaseModelEndpoint {
  public create<T, ResponseType>({
    args,
    queryParams,
    appendEndSlash,
    newEntity,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
    newEntity: T;
  }): Promise<Response<ResponseType>> {
    // Generate URL
    const url = this._generateUrl({ args, queryParams, appendEndSlash });

    return axios.post(url.toString(), newEntity);
  }
}
