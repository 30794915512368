// local
import CreateModelEndpoint from "./CreateModelEndpoint";
import { URLMethods, URLTypes } from "./enums";
import ListModelEndpoint from "./ListModelEndpoint";
import UpdateModelEndpoint from "./UpdateModelEndpoint";
import RetrieveModelEndpoint from "./RetrieveModelEndpoint";
import ModelEndpoint from "./ModelEndpoint";
import DestroyModelEndpoint from "./DestroyModelEndpoint";

// thirdparty

export default ModelEndpoint;
export {
  CreateModelEndpoint,
  DestroyModelEndpoint,
  ListModelEndpoint,
  UpdateModelEndpoint,
  RetrieveModelEndpoint,
  URLTypes,
  URLMethods,
};
