// react
import React, { useEffect } from "react";

// local
import ProfileButton from "./ProfileButton";
import SideDrawer from "./SideDrawer";
import LockPath from "../../private/LockPath";

// thirdparty
import { Box, AppBar, Toolbar, Typography, Stack } from "@mui/material";

interface Props {
  children: JSX.Element;
  title?: string;
}

const Layout: React.FC<Props> = ({ children, title }) => {
  // Side-Effects
  useEffect(() => {
    document.title = `${title} | Time Tracker`;
  }, [title]);

  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <AppBar position="static">
        <Toolbar>
          <SideDrawer />
          {title ? <Typography>{title}</Typography> : <></>}
          <Stack direction="row" justifyContent="right" sx={{ flexGrow: 1 }}>
            {title ? <LockPath title={title} /> : <></>}
            <ProfileButton />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          display: "flex",
          overflowY: "auto",
          py: 4,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
