// react
import React, { useEffect, useState } from "react";

// local
import { useAppSelector } from "../../../../reduxConfig/reduxHooks";

// thirdparty
import { Alert, Snackbar } from "@mui/material";

export const AlertSection = () => {
  // State
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  // Redux
  const { alertMessage } = useAppSelector((state) => state.alert);

  // Side-Effect
  useEffect(() => {
    setAlertOpen(Boolean(alertMessage));
  }, [alertMessage]);

  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={3000}
      onClose={() => setAlertOpen(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => setAlertOpen(false)}
        severity={alertMessage?.severity}
        sx={{ width: "100%" }}
      >
        {alertMessage?.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSection;
