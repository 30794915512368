// local

// thirdparty
import { setIn } from "final-form";

export const validate = async (values: any, schema: Record<string, any>) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (e: any) {
    if (e.inner) {
      return e.inner.reduce((errors: any, error: any) => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  }
};

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
