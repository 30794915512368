// local
import ModelEndpoint from "./ModelEndpoint";

// thirdparty

export default class AllowedIPService {
  private static _instance: AllowedIPService;
  public AllowedIP: ModelEndpoint;

  constructor() {
    this.AllowedIP = new ModelEndpoint("/api/v1/allowed-ips/");
  }

  static getInstance() {
    if (!AllowedIPService._instance) {
      AllowedIPService._instance = new AllowedIPService();
    }
    return AllowedIPService._instance;
  }
}
