// react
import { useEffect, useState } from "react";

// local
import { PageResponse, Response } from "../../../../interfaces/Response";

// thirdparty
import {
  Autocomplete,
  CircularProgress,
  TextField,
  TextFieldProps,
} from "@mui/material";

export interface Props<T> {
  fetchOptions: (
    newSearchString: string
  ) => Promise<Response<T[] | PageResponse<T>>>;
  TextFieldProps: TextFieldProps;
  getOptionLabel: (option: T) => string;
  onChange?: (event: unknown, option: T | null) => void;
}

const RemoteAutocomplete = <T,>({
  fetchOptions,
  getOptionLabel,
  onChange,
  TextFieldProps,
}: Props<T>) => {
  // State
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  // Side-Effects
  useEffect(() => {
    if (inputValue !== "") {
      setLoading(true);
      fetchOptions(inputValue)
        .then((response) => {
          if ("results" in response.data) {
            setOptions((response.data as PageResponse<T>).results);
          } else {
            setOptions(response.data);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOpen(false);
      setOptions([]);
    }
  }, [inputValue, fetchOptions]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      filterOptions={(option) => option}
      open={open}
      onOpen={() => {
        if (options.length > 0) setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          {...RemoteAutocomplete.defaultProps.TextFieldProps}
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
RemoteAutocomplete.defaultProps = {
  TextFieldProps: {
    variant: "standard" as "standard",
    label: "Remote Autocomplete",
  },
};

export default RemoteAutocomplete;
