// react

// local
import AllowedIPService from "../../../../services/AllowedIPService";
import { URLMethods, URLTypes } from "../../../../services/ModelEndpoint";
import { raiseAlert } from "../../../../helpers/alertHelpers";

// thirdparty
import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

interface Props {
  value: string;
  onChange: (value: unknown) => void;
}

const IPAddressInput = (props: Props) => {
  // Service
  const allowedIPService = AllowedIPService.getInstance();

  // Helpers
  const autoFillIPAddress = () => {
    allowedIPService.AllowedIP.action({
      args: [],
      urlType: URLTypes.LIST,
      actionURL: "/api/v1/allowed-ips/current-ip-address/",
      method: URLMethods.GET,
    })
      .then((response) => {
        props.onChange(response.data);
      })
      .catch((error) => {
        console.error(error);
        raiseAlert("There was an issue pulling your IP address.", "error");
      });
  };

  const parseInput = (value: string, field: number = 0) => {
    const splitInput = value.split(".");

    return splitInput[field];
  };

  const updateInput = (changeString: string, field: number = 0) => {
    if (!/^\d{0,3}$/.test(changeString)) {
      return;
    }

    let splitInput = props.value.split(".");
    splitInput[field] = changeString;
    const newValue = `${splitInput[0] ?? ""}.${splitInput[1] ?? ""}.${
      splitInput[2] ?? ""
    }.${splitInput[3] ?? ""}`;
    props.onChange(newValue);
  };

  return (
    <Stack direction="row" alignItems="end">
      <TextField
        variant="standard"
        placeholder="000"
        inputProps={{
          value: parseInput(props.value),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            updateInput(e.target.value),
          minLength: 1,
          maxLength: 3,
        }}
      />
      <Typography sx={{ mx: 1, mb: -1 }}>.</Typography>
      <TextField
        variant="standard"
        placeholder="000"
        inputProps={{
          value: parseInput(props.value, 1),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            updateInput(e.target.value, 1),
          minLength: 1,
          maxLength: 3,
        }}
      />
      <Typography sx={{ mx: 1, mb: -1 }}>.</Typography>
      <TextField
        variant="standard"
        placeholder="000"
        inputProps={{
          value: parseInput(props.value, 2),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            updateInput(e.target.value, 2),
          minLength: 1,
          maxLength: 3,
        }}
      />
      <Typography sx={{ mx: 1, mb: -1 }}>.</Typography>
      <TextField
        variant="standard"
        placeholder="000"
        inputProps={{
          value: parseInput(props.value, 3),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            updateInput(e.target.value, 3),
          minLength: 1,
          maxLength: 3,
        }}
      />
      <Tooltip title="Autofill Current IP Address">
        <IconButton onClick={autoFillIPAddress}>
          <AutoAwesomeMotionIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default IPAddressInput;
