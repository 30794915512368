// local
import { Response } from "../../interfaces/Response";
import BaseModelEndpoint from "./BaseModelEndpoint";
import { URLTypes } from "./enums";

// thirdparty
import axios from "axios";

export default class UpdateModelEndpoint extends BaseModelEndpoint {
  public partialUpdate<T, ResponseType>({
    args,
    queryParams,
    appendEndSlash,
    updatedEntity,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
    updatedEntity: Partial<T>;
  }): Promise<Response<ResponseType>> {
    const url = this._generateUrl({
      args,
      queryParams,
      appendEndSlash,
      urlType: URLTypes.DETAIL,
    });

    return axios.patch(url.toString(), updatedEntity);
  }

  public update<T, ResponseType>({
    args,
    queryParams,
    appendEndSlash,
    updatedEntity,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
    updatedEntity: T;
  }): Promise<Response<ResponseType>> {
    const url = this._generateUrl({
      args,
      queryParams,
      appendEndSlash,
      urlType: URLTypes.DETAIL,
    });

    return axios.put(url.toString(), updatedEntity);
  }
}
