// local
import { AlertMessage } from "../../interfaces/Alert";

// thirdparty
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AlertState {
  alertMessage: AlertMessage | null;
}

const initialState: AlertState = {
  alertMessage: null,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlertMessage(state, action: PayloadAction<AlertMessage | null>) {
      state.alertMessage = action.payload;
    },
  },
});

export const { reducer: alertReducer } = alertSlice;
export const { setAlertMessage } = alertSlice.actions;
