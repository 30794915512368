// react
import React, { useState } from "react";

// local
import { routes } from "../../../configuration/routes";
import { RouteDefinition } from "../../../interfaces/RouteDefinition";
import { hasPermission } from "../../../hooks/permissionHooks";

// thirdparty
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router";
import {
  useAppSelector,
  useLoggedInUser,
} from "../../../reduxConfig/reduxHooks";

const SideDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // React Router
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigationClick = (route: RouteDefinition) => {
    setDrawerOpen(false);
    if (route.path !== pathname) navigate(route.path);
  };

  const routeIsHiddenInDrawer = (route: RouteDefinition) => {
    if (route.hideFromDrawer && typeof route.hideFromDrawer === "function") {
      return route.hideFromDrawer();
    }
    return route.hideFromDrawer;
  };

  // Redux
  const userToCheck = useLoggedInUser();
  const { passcode } = useAppSelector((state) => state.path);

  return (
    <>
      {!Boolean(passcode) ? (
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <></>
      )}
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 300 }}>
          <List>
            {routes
              .filter(
                (route) =>
                  hasPermission(route.permissions, userToCheck) &&
                  route.icon &&
                  !routeIsHiddenInDrawer(route)
              )
              .map((route, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => handleNavigationClick(route)}
                >
                  <ListItemButton selected={route.path === pathname}>
                    <ListItemIcon>
                      {route.icon ? <route.icon /> : <></>}
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SideDrawer;
