// react

// local
import { User } from "../../../../interfaces/User";
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from "../RemoteAutocomplete";
import UserService from "../../../../services/UserService";
import { PageResponse } from "../../../../interfaces/Response";

// thirdparty

interface Props extends Pick<RemoteAutocompleteProps<User>, "onChange"> {}

const UserRemoteAutocomplete = (props: Props) => {
  const fetchOptions = (newSearchString: string) => {
    return UserService.getInstance().User.list<PageResponse<User>>({
      args: [],
      queryParams: { search: newSearchString },
    });
  };

  return (
    <RemoteAutocomplete<User>
      {...props}
      TextFieldProps={{
        label: "Users",
      }}
      fetchOptions={fetchOptions}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default UserRemoteAutocomplete;
