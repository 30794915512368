// local
import { Response } from "../../interfaces/Response";
import BaseModelEndpoint from "./BaseModelEndpoint";

// thirdparty
import axios from "axios";

export default class RetrieveModelEndpoint extends BaseModelEndpoint {
  public retrieve<ResponseType>({
    args,
    queryParams,
    appendEndSlash,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
  }): Promise<Response<ResponseType>> {
    // Generate URL
    const url = this._generateUrl({ args, queryParams, appendEndSlash });

    return axios.get(url.toString());
  }
}
