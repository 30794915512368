// local
import {
  logout,
  passcodeAuthenticateUser,
  passwordAuthenticateUser,
} from "../thunks/authThunks";
import { User } from "../../interfaces/User";

// thirdparty
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpStatuses from "../../enums/HttpStatuses";

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    forceLogout: (state) => {
      return {
        isAuthenticated: false,
        user: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(passwordAuthenticateUser.fulfilled, (state, action) => {
      if (action.payload.status === HttpStatuses.HTTP_200_OK) {
        state.user = action.payload.data;
        state.isAuthenticated = true;
      }
    });
    builder.addCase(passcodeAuthenticateUser.fulfilled, (state, action) => {
      if (action.payload.status === HttpStatuses.HTTP_200_OK) {
        state.user = action.payload.data;
        state.isAuthenticated = true;
      }
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      if (action.payload.status === HttpStatuses.HTTP_204_NO_CONTENT) {
        state.isAuthenticated = false;
        state.user = null;
      }
    });
  },
});

export const { reducer: authReducer } = authSlice;
export const { setUser, setAuthenticated, forceLogout } = authSlice.actions;
