// local
import { URLMethods, URLTypes } from "./enums";
import { URLMethod, URLType } from "./types";
import { Response } from "../../interfaces/Response";

// thirdparty
import axios from "axios";

interface ModelEndpointOptions {
  appendEndSlash: boolean;
}

const DEFAULT_OPTIONS: ModelEndpointOptions = {
  appendEndSlash: true,
};

export default class BaseModelEndpoint {
  protected baseUrl;
  protected endpointOptions: ModelEndpointOptions = DEFAULT_OPTIONS;

  constructor(baseUrl: string, options?: Partial<ModelEndpointOptions>) {
    this.baseUrl = baseUrl;
    if (options) {
      this.endpointOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
      };
    }
  }

  private _determineAxiosMethod(method: URLMethod) {
    switch (method) {
      case URLMethods.GET:
        return axios.get;
      case URLMethods.DELETE:
        return axios.delete;
      case URLMethods.PUT:
        return axios.put;
      case URLMethods.PATCH:
        return axios.patch;
      case URLMethods.OPTIONS:
        return axios.options;
    }
  }

  protected _generateUrl({
    actionURL,
    args,
    queryParams,
    appendEndSlash,
    urlType = URLTypes.LIST,
  }: {
    actionURL?: string;
    args: string[];
    queryParams?: Record<string, string | undefined>;
    appendEndSlash?: boolean;
    urlType?: URLType;
  }) {
    const url = new URL(window.location.origin);

    // Determine value of appendEndSlash should
    let shouldAppendEndSlash = false;
    if (appendEndSlash === undefined) {
      shouldAppendEndSlash = this.endpointOptions.appendEndSlash;
    }

    // Update the pathname
    url.pathname = this._replacePathnameArgs(
      args,
      shouldAppendEndSlash,
      urlType,
      actionURL
    );

    // Append the queryParams
    if (queryParams) {
      Object.keys(queryParams)
        .filter((key) => queryParams[key] !== undefined)
        .forEach((key) => {
          url.searchParams.append(key, queryParams[key] as string);
        });
    }

    // Return the last
    return url;
  }

  protected _replacePathnameArgs(
    args: string[],
    appendEndSlash: boolean,
    urlType: URLType,
    actionURL?: string
  ) {
    let newPathName = this.baseUrl;
    if (actionURL) {
      newPathName = actionURL;
    }
    const argRegex = /[:][\w]+/i;
    let lastArg = undefined;
    if (urlType === URLTypes.DETAIL) {
      lastArg = args.pop();
    }

    // Replace arguments
    args.forEach((arg) => {
      newPathName = newPathName.replace(argRegex, arg);
    });

    // Add Arg
    if (urlType === URLTypes.DETAIL && lastArg) {
      if (newPathName.slice(-1) !== "/") {
        newPathName = `${newPathName}/`;
      }
      newPathName = `${newPathName}${lastArg}`;
    }

    // Append End Slash
    if (appendEndSlash) {
      newPathName = `${newPathName}${newPathName.slice(-1) !== "/" ? "/" : ""}`;
    }

    return newPathName;
  }

  public options({
    args,
    queryParams,
    appendEndSlash,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
  }) {
    const url = this._generateUrl({
      args,
      queryParams,
      appendEndSlash,
      urlType: URLTypes.DETAIL,
    });

    return axios.options(url.toString());
  }

  public action<T, ResponseType>({
    args,
    queryParams,
    appendEndSlash,
    method,
    actionURL,
    submissionData,
    urlType = URLTypes.LIST,
  }: {
    actionURL: string;
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
    method: URLMethod;
    submissionData?: T;
    urlType?: URLType;
  }): Promise<Response<ResponseType>> {
    const url = this._generateUrl({
      actionURL,
      args,
      queryParams,
      appendEndSlash,
      urlType,
    });

    const submissionMethod = this._determineAxiosMethod(method);
    return submissionMethod(url.toString(), submissionData);
  }
}
