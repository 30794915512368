// react
import React, { useState } from "react";

// local
import { useLoggedInUser } from "../../../reduxConfig/reduxHooks";

// thirdparty
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import PasswordUpdateForm from "./PasswordUpdateForm";
import PasscodeUpdateForm from "./PasscodeUpdateForm/PasscodeUpdateForm";

const Profile = () => {
  // State
  const [showPasswordUpdateForm, setShowPasswordUpdateForm] =
    useState<boolean>(false);
  const [showPasscodeUpdateForm, setShowPasscodeUpdateForm] =
    useState<boolean>(false);

  // User
  const user = useLoggedInUser();

  return (
    <>
      <Container
        sx={{
          mt: 2,
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Card elevation={0}>
              <CardHeader title="Info" sx={{ pb: 0 }} />
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Typography>Name:</Typography>
                  <Typography>{user?.name}</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography>Email:</Typography>
                  <Typography>{user?.email}</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card elevation={0}>
              <CardHeader title="Authentication" sx={{ pb: 0 }} />
              <CardContent>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={2} alignItems="flex-end">
                    <Typography>Password:</Typography>
                    <Typography>****</Typography>
                    <Button
                      size="small"
                      onClick={() => setShowPasswordUpdateForm(true)}
                    >
                      Update
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="flex-end">
                    <Typography>Passcode:</Typography>
                    <Typography>****</Typography>
                    <Button
                      size="small"
                      onClick={() => setShowPasscodeUpdateForm(true)}
                    >
                      Update
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <PasswordUpdateForm
        showDialog={showPasswordUpdateForm}
        closeDialog={() => setShowPasswordUpdateForm(false)}
      />
      <PasscodeUpdateForm
        showDialog={showPasscodeUpdateForm}
        closeDialog={() => setShowPasscodeUpdateForm(false)}
      />
    </>
  );
};

export default Profile;
