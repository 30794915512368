// react
import { useEffect, useState } from "react";

// local
import { raiseAlert } from "../../../../helpers/alertHelpers";
import { useLoggedInUser } from "../../../../reduxConfig/reduxHooks";

// thirdparty
import axios from "axios";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

enum TokenValidationStatus {
  CHECKING = "CHECKING",
  VALID = "VALID",
  INVALID = "INVALID",
  NONE = "NONE",
}

const TokenCreate = () => {
  // State
  const [tokenValidationStatus, setTokenValidationStatus] =
    useState<TokenValidationStatus>(TokenValidationStatus.CHECKING);

  // React Router
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  // Redux
  const loggedInUser = useLoggedInUser();

  // Helpers
  const submitData = (values: Record<string, string>) => {
    axios
      .post(`/api/v1/submission/create/?token=${queryParams.get("token")}`, {
        comment: values.comment ?? "",
      })
      .then((response) => {
        raiseAlert("Your time has been successfully submitted!");
        navigate("/home", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        raiseAlert("There was an issue submitting your time.", "error");
      });
  };

  // Side-Effects
  useEffect(() => {
    if (!queryParams.get("token")) {
      setTokenValidationStatus(TokenValidationStatus.NONE);
    } else {
      axios
        .patch(`/api/v1/validate-token/?token=${queryParams.get("token")}`)
        .then(() => {
          setTokenValidationStatus(TokenValidationStatus.VALID);
        })
        .catch(() => {
          setTokenValidationStatus(TokenValidationStatus.INVALID);
        });
    }
  }, [queryParams]);

  switch (tokenValidationStatus) {
    case TokenValidationStatus.CHECKING:
      return <CircularProgress />;
    case TokenValidationStatus.NONE:
    case TokenValidationStatus.INVALID:
      raiseAlert("Invalid token provided.", "error");
      return <Navigate to="/home" />;
    case TokenValidationStatus.VALID:
      return (
        <Container maxWidth="xs">
          <Stack spacing={2}>
            <Typography align="center" variant="h4">
              Welcome {loggedInUser?.name}!
            </Typography>
            <Card>
              <Form onSubmit={submitData}>
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <CardContent>
                      <TextField
                        name="comment"
                        label="Comment"
                        variant="standard"
                        multiline
                        rows={3}
                      />
                    </CardContent>
                    <CardActions sx={{ justifyContent: "right" }}>
                      <Button type="submit">Submit</Button>
                    </CardActions>
                  </form>
                )}
              </Form>
            </Card>
          </Stack>
        </Container>
      );
  }
};

export default TokenCreate;
