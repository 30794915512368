import { Permissions } from "../enums/permissions";
import { User } from "../interfaces/User";
import { store } from "../reduxConfig/reduxStore";

const hasPermission = (
  allowPermission: Permissions | Permissions[],
  userToCheck: User | null = null
) => {
  const {
    auth: { user },
  } = store.getState();

  if (userToCheck === null && user === null) {
    return false;
  }

  const { role } = (userToCheck || user) as User;

  if (allowPermission.constructor === Array) {
    return allowPermission.some(
      (allowedPermission) => allowedPermission <= role
    );
  } else {
    return allowPermission <= role;
  }
};

export { hasPermission };
