// local
import {
  SubmissionTypes,
  TimeSubmission,
} from "../../../../../interfaces/TimeSubmission";
import { msToHMS } from "../../../../../helpers/dateHelpers";

// thirdparty
import { chunk } from "lodash";

export const calculateTotalElapsedHours = (
  timeSubmissions: TimeSubmission[]
): string => {
  if (timeSubmissions.length >= 2) {
    const chunkedTimeSubmissions = chunk(timeSubmissions, 2);
    const mappedTimes = chunkedTimeSubmissions?.map((chunkedTimes) => {
      if (chunkedTimes?.length !== 2) {
        return 0;
      }
      const checkInSubmission = chunkedTimes?.find(
        (submission) => submission.submission_type === SubmissionTypes.CHECK_IN
      );
      const checkOutSubmission = chunkedTimes?.find(
        (submission) => submission.submission_type === SubmissionTypes.CHECK_OUT
      );
      if (checkInSubmission && checkOutSubmission) {
        const checkInTime = new Date(checkInSubmission.submission_datetime);
        const checkOutTime = new Date(checkOutSubmission.submission_datetime);
        return checkOutTime.getTime() - checkInTime.getTime();
      }
      return 0;
    });

    const summedElapsedTime = mappedTimes.reduce(
      (time, currentTime) => time + currentTime
    );
    return msToHMS(summedElapsedTime);
  }
  return "NA";
};
