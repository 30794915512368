// react
import React, { useState } from "react";

// local
import { User } from "../../../../interfaces/User";
import { getNDayOfWeek } from "../../../../helpers/dateHelpers";
import WeeklySummary from "../../../public/Shared/WeeklySummary";
import DateSelection from "../../../public/Shared/DateSelection";

// thirdparty
import { Card, CardContent, CardHeader } from "@mui/material";

interface Props {
  user: User | null;
}

const SubmissionWeeklySummary: React.FC<Props> = ({ user }) => {
  // State
  const [selectedDate, setSelectedDate] = useState<Date>(
    getNDayOfWeek(0, new Date(new Date().setHours(0, 0, 0, 0)))
  );

  return (
    <Card sx={{ mb: 4 }}>
      <CardHeader
        title="Weekly Summary"
        action={
          <DateSelection
            initialSelection={selectedDate}
            onChange={(newDate) => {
              setSelectedDate(newDate);
            }}
            includeWeekends
          />
        }
      />
      <CardContent>
        {user ? (
          <WeeklySummary selectedDate={selectedDate} userGuid={user.uuid} />
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default SubmissionWeeklySummary;
