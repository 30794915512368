// react
import React, { useEffect, useMemo, useState } from "react";

// local
import { calculateTotalElapsedHours } from "./helpers";
import DailySummary from "./DailySummary";
import { TimeSubmission } from "../../../../../interfaces/TimeSubmission";
import DailyEditDialog from "./DailyEditDialog";
import { UUID } from "../../../../../types";
import { hasPermission } from "../../../../../hooks/permissionHooks";
import { PermissionName } from "../../../../../enums/permissions";

// thirdparty
import {
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  weekDay: Date;
  timeSubmissions: TimeSubmission[];
  userGuid: UUID | null;
  refresh?: () => void;
}

const GeneratedDay: React.FC<Props> = ({
  weekDay,
  timeSubmissions,
  userGuid,
  refresh,
}) => {
  // State
  const [sortedDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortedSubmissions, setSortedSubmissions] = useState<TimeSubmission[]>(
    []
  );
  const [editTimeSubmissionDialogOpen, setEditTimeSubmissionDialogOpen] =
    useState<boolean>(false);

  // Helpers
  const calculateVisibleItems = (
    timeSubmissions: TimeSubmission[],
    weekDay: Date
  ) =>
    timeSubmissions.filter(
      (timeSubmission) =>
        new Date(timeSubmission.submission_datetime).getDate() ===
        weekDay.getDate()
    );

  const handleDailyEditDialogClose = () => {
    refresh?.();
    setEditTimeSubmissionDialogOpen(false);
  };

  // Side-Effects
  useEffect(() => {
    if (sortedDirection === "asc") {
      setSortedSubmissions(
        calculateVisibleItems(timeSubmissions, weekDay).reverse()
      );
    } else {
      setSortedSubmissions(calculateVisibleItems(timeSubmissions, weekDay));
    }
  }, [weekDay, timeSubmissions, sortedDirection]);

  // Memoized data
  const elapsedTime = useMemo(
    () =>
      calculateTotalElapsedHours(
        calculateVisibleItems(timeSubmissions, weekDay).reverse()
      ),
    [weekDay, timeSubmissions]
  );

  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        <Box sx={{ mb: 5 }}>
          <Divider />
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarTodayIcon fontSize="small" />
            <Typography
              sx={{
                mb: 0,
              }}
            >
              {new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(
                weekDay
              )}{" "}
              {weekDay.toLocaleDateString()}
            </Typography>
            {hasPermission(PermissionName.ADMIN) ? (
              <Tooltip title="Edit Time Submissions">
                <IconButton
                  onClick={() => setEditTimeSubmissionDialogOpen(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
            <IconButton
              onClick={() =>
                setSortDirection(sortedDirection === "asc" ? "desc" : "asc")
              }
              sx={{
                marginLeft: "auto!important",
              }}
            >
              {sortedDirection === "asc" ? (
                <ArrowUpwardIcon />
              ) : (
                <ArrowDownwardIcon />
              )}
            </IconButton>
          </Stack>
          <Divider />
          <DailySummary timeSubmissions={sortedSubmissions} weekDay={weekDay} />
          <Divider />
          <Typography>Elapsed: {elapsedTime}</Typography>
          <Divider />
        </Box>
      </Grid>
      <DailyEditDialog
        open={editTimeSubmissionDialogOpen}
        onClose={handleDailyEditDialogClose}
        timeSubmissions={calculateVisibleItems(timeSubmissions, weekDay)}
        weekDay={weekDay}
        userGuid={userGuid}
      />
    </>
  );
};

export default GeneratedDay;
