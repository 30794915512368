// local
import { setAlertMessage } from "../reduxConfig/slices/alertSlice";
import { store } from "../reduxConfig/reduxStore";

// thirdparty
import { AlertColor } from "@mui/material";

export const raiseAlert = (
  message: string,
  severity: AlertColor = "success"
) => {
  store.dispatch(setAlertMessage({ message: message, severity: severity }));
};

export const unAuthorizedAlert = () => {
  raiseAlert("You are unauthorized. Please login again.", "error");
};

export const closeAlert = () => {
  store.dispatch(setAlertMessage(null));
};
