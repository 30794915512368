// react
import React, { useState } from "react";

// local

// thirdparty
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { TextField, TextFieldProps } from "mui-rff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";

interface Props extends TextFieldProps {
  hideStartAdornment: boolean;
  toggleTooltip: {
    hideTooltip: string;
    showTooltip: string;
  };
}

const PasswordField = (props: Props) => {
  const { hideStartAdornment, toggleTooltip, ...restProps } = props;

  // State
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <TextField
      {...restProps}
      type={passwordVisible ? "text" : "password"}
      InputProps={{
        startAdornment: !hideStartAdornment ? (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ) : (
          <></>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip
              title={
                passwordVisible
                  ? toggleTooltip.hideTooltip
                  : toggleTooltip.showTooltip
              }
            >
              <IconButton
                onClick={() => setPasswordVisible(!passwordVisible)}
                tabIndex={-1}
              >
                {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordField.defaultProps = {
  name: "passcode",
  label: "Pin",
  hideStartAdornment: false,
  variant: "standard",
  autoFocus: true,
  toggleTooltip: {
    showTooltip: "Show",
    hideTooltip: "Hide",
  },
};

export default PasswordField;
