// react

import WeeklySummaryTable from "./WeeklySummaryTable";

// local

// thirdparty

const UserWeeklySummary = () => {
  return <WeeklySummaryTable />;
};

export default UserWeeklySummary;
