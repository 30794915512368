// local
import { PageResponse, Response } from "../interfaces/Response";
import { TimeSubmissionReport } from "../interfaces/TimeSubmissionReport";

// thirdparty
import axios from "axios";
import ModelEndpoint from "./ModelEndpoint";

export default class ReportService {
  private static _instance: ReportService;
  public TimeSubmission: ModelEndpoint = new ModelEndpoint(
    "/api/v1/reports/user-submissions/"
  );

  static getInstance(): ReportService {
    if (!ReportService._instance) {
      ReportService._instance = new ReportService();
    }
    return ReportService._instance;
  }
}
