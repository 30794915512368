// local
import { User } from "../interfaces/User";
import { PageResponse, Response } from "../interfaces/Response";

// thirdparty
import axios from "axios";
import { UUID } from "../types";
import UpdateModelEndpoint from "./ModelEndpoint/UpdateModelEndpoint";
import ModelEndpoint from "./ModelEndpoint";

export default class UserService {
  private static _instance: UserService;
  public ChangePassword: UpdateModelEndpoint = new UpdateModelEndpoint(
    "/api/v1/authentication/change-password/"
  );
  public ChangePasscode: UpdateModelEndpoint = new UpdateModelEndpoint(
    "/api/v1/authentication/change-passcode"
  );
  public User: ModelEndpoint = new ModelEndpoint("/api/v1/users/");

  static getInstance(): UserService {
    if (!UserService._instance) {
      UserService._instance = new UserService();
    }
    return UserService._instance;
  }

  public create({
    args = [],
    newEntity,
  }: {
    args?: string[];
    newEntity: Pick<User, "name" | "email">;
  }): Promise<Response<User>> {
    const url = new URL(window.location.origin);
    url.pathname = "/api/v1/users/";
    return axios.post(url.toString(), newEntity);
  }

  public list({
    queryParams,
  }: {
    queryParams?: Record<string, string | undefined>;
  }): Promise<Response<PageResponse<User[]>>> {
    const url = new URL(window.location.origin);
    url.pathname = "/api/v1/users/";
    if (queryParams) {
      Object.keys(queryParams).forEach((key) => {
        const queryValue = queryParams[key];
        if (queryValue) {
          url.searchParams.append(key, queryValue);
        }
      });
    }

    return axios.get(url.toString());
  }

  public retrieve(userGuid: UUID): Promise<Response<User>> {
    return axios.get(`/api/v1/users/${userGuid}`);
  }

  public update({
    args = [],
    updateEntity,
  }: {
    args?: string[];
    updateEntity: User;
  }): Promise<Response<User>> {
    const url = new URL(window.location.origin);
    url.pathname = `/api/v1/users/${args[0]}/`;

    return axios.put(url.toString(), updateEntity);
  }

  public delete({ args = [] }: { args?: string[] }) {
    const url = new URL(window.location.origin);
    url.pathname = `/api/v1/users/${args[0]}/`;

    return axios.delete(url.toString());
  }
}
