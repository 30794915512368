// react
import React, { useEffect, useState } from "react";

// local
import { User } from "../../../interfaces/User";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
  TextField,
  AlertColor,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import axios from "axios";
import * as Yup from "yup";
import { validate } from "../../../helpers/generalHelpers";
import { raiseAlert } from "../../../helpers/alertHelpers";

interface Props {
  selectedUser: User | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const SubmitTimeUserDialog: React.FC<Props> = ({
  selectedUser,
  setSelectedUser,
}) => {
  // State
  const [pinAlert, setPinAlert] = useState<string | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState<boolean>(Boolean(selectedUser));

  // Helpers
  const handleSubmission = (values: Record<string, string>) => {
    axios
      .post("/api/v1/generate-token/", {
        email: selectedUser?.email,
        password_or_passcode: values.password_or_passcode,
      })
      .then(async (response) => {
        axios
          .post(`/api/v1/submission/create/?token=${response.data}`, {
            user: selectedUser?.uuid,
            comment: values.comment ?? "",
          })
          .then(() => {
            setDialogOpen(false);
            raiseAlert("Your time has been successfully submitted.", "success");
          })
          .catch(() => {
            raiseAlert("There was an issue submitting your time.", "error");
          });
      })
      .catch((error) => {
        console.error(error);
        const nonFieldErrors = error.response.data.non_field_errors;
        if (nonFieldErrors?.[0] === "Incorrect credentials") {
          setPinAlert("Your entered pin was incorrect.");
        }
      });
  };

  const generateValidationSchema = () => {
    return Yup.object().shape({
      password_or_passcode: Yup.string().required("You must provide your pin or password."),
    });
  };

  // Side-effects
  useEffect(() => {
    if (!selectedUser) {
      setPinAlert(undefined);
    } else {
      setDialogOpen(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!dialogOpen) {
      setSelectedUser(null);
    }
  }, [dialogOpen, setSelectedUser]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      maxWidth="xs"
      fullWidth
    >
      <Form
        onSubmit={handleSubmission}
        validate={(values: Record<string, string>) =>
          validate(values, generateValidationSchema())
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography variant="h5">
                Welcome, {selectedUser?.name}!
              </Typography>
              <Stack spacing={1}>
                <Field name="password_or_passcode">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Pin or Password"
                      fullWidth
                      type="password"
                      variant="standard"
                      onKeyDown={() => setPinAlert(undefined)}
                      helperText={
                        !!(
                          (((meta.submitError && !meta.dirtySinceLastSubmit) ||
                            meta.error) &&
                            (meta.touched || meta.modified)) ||
                          Boolean(pinAlert)
                        )
                          ? pinAlert || meta.error
                          : undefined
                      }
                      error={
                        !!(
                          (((meta.submitError && !meta.dirtySinceLastSubmit) ||
                            meta.error) &&
                            (meta.touched || meta.modified)) ||
                          Boolean(pinAlert)
                        )
                      }
                    />
                  )}
                </Field>
                <TextField
                  name="comment"
                  label="Comment"
                  fullWidth
                  variant="standard"
                  multiline
                  rows={3}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default SubmitTimeUserDialog;
