// react
import { useEffect, useState } from "react";

// local
import { User } from "../../../interfaces/User";
import UserService from "../../../services/UserService";
import GridCell from "./GridCell/GridCell";
import SubmissionTimeQRCode from "./SubmissionTimeQRCode/SubmissionTimeQRCode";
import SubmitTimeUserDialog from "./SubmitTimeUserDialog";
import { PageResponse } from "../../../interfaces/Response";

// thirdparty
import { Box, Container, Divider, Skeleton, Typography } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";
import { chunk } from "lodash";
import InfiniteLoader from "react-window-infinite-loader";

const SubmitTime = () => {
  const [users, setUsers] = useState<PageResponse<User>>({
    count: 0,
    results: [],
    next: null,
    previous: null,
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gridSetup, setGridSetup] = useState<{ row: number; col: number }>({
    row: 0,
    col: 0,
  });

  // Helpers
  const helper = () => {
    setIsLoading(true);
    UserService.getInstance()
      .User.list<PageResponse<User>>({
        args: [],
        queryParams: {
          pagination: "LimitOffset",
          limit: "6",
          offset: users.results.length.toString(),
        },
      })
      .then((response) => {
        setUsers({
          ...response.data,
          results: [...users.results, ...response.data.results],
        });
        setIsLoading(false);
      });
  };

  // Side-Effects
  useEffect(() => {
    UserService.getInstance()
      .User.list<PageResponse<User>>({
        args: [],
        queryParams: { limit: "6", offset: "0", pagination: "LimitOffset" },
      })
      .then((response) => {
        setUsers(response.data);
      });
  }, []);

  useEffect(() => {
    if (users.count >= 3) {
      setGridSetup({ row: Math.ceil(users.count / 3), col: 3 });
    } else {
      setGridSetup({ col: users.count, row: 1 });
    }
  }, [users]);

  if (gridSetup.col > 0 && gridSetup.row > 0) {
    return (
      <>
        <Container sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              mb: 2,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" align="center">
              Scan the QR to Clock In/Out
            </Typography>
            <SubmissionTimeQRCode />
          </Box>
          <Divider>OR</Divider>
          <Typography variant="h4" align="center" sx={{ my: 2 }}>
            Choose your card below
          </Typography>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <InfiniteLoader
                isItemLoaded={(index: number) => {
                  return users.next === null || index < users.results.length;
                }}
                itemCount={
                  users.next !== null
                    ? users.results.length + 1
                    : users.results.length
                }
                loadMoreItems={isLoading ? () => {} : helper}
              >
                {({ onItemsRendered, ref }: any) => {
                  const newItemsRendered = (gridData: any) => {
                    const useOverscanForLoading = true;
                    const {
                      visibleRowStartIndex,
                      visibleRowStopIndex,
                      visibleColumnStopIndex,
                      overscanRowStartIndex,
                      overscanRowStopIndex,
                      overscanColumnStopIndex,
                    } = gridData;

                    const endCol =
                      (useOverscanForLoading || true
                        ? overscanColumnStopIndex
                        : visibleColumnStopIndex) + 1;

                    const startRow =
                      useOverscanForLoading || true
                        ? overscanRowStartIndex
                        : visibleRowStartIndex;
                    const endRow =
                      useOverscanForLoading || true
                        ? overscanRowStopIndex
                        : visibleRowStopIndex;

                    const visibleStartIndex = startRow * endCol;
                    const visibleStopIndex = endRow * endCol;

                    onItemsRendered({
                      //call onItemsRendered from InfiniteLoader so it can load more if needed
                      visibleStartIndex,
                      visibleStopIndex,
                    });
                  };

                  return (
                    <Grid
                      columnCount={gridSetup.col}
                      rowCount={gridSetup.row}
                      itemData={users.results}
                      height={height}
                      width={width}
                      columnWidth={() => width / gridSetup.col}
                      rowHeight={() => 300}
                      onItemsRendered={newItemsRendered}
                      ref={ref}
                    >
                      {(props) => (
                        <GridCell
                          {...props}
                          chunkedData={chunk(props.data, gridSetup.col)}
                          setSelectedUser={setSelectedUser}
                        />
                      )}
                    </Grid>
                  );
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Container>
        <SubmitTimeUserDialog
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </>
    );
  } else {
    return <Skeleton />;
  }
};

export default SubmitTime;
