// local
import { Response } from "../../interfaces/Response";
import BaseModelEndpoint from "./BaseModelEndpoint";
import { URLTypes } from "./enums";

// thirdparty
import axios from "axios";

export default class DestroyModelEndpoint extends BaseModelEndpoint {
  public destroy<ResponseType>({
    args,
    queryParams,
    appendEndSlash,
  }: {
    args: string[];
    queryParams?: Record<string, string>;
    appendEndSlash?: boolean;
  }): Promise<Response<ResponseType>> {
    const url = this._generateUrl({
      args,
      queryParams,
      appendEndSlash,
      urlType: URLTypes.DETAIL,
    });

    return axios.delete(url.toString());
  }
}
