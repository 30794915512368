// react
import React, { useEffect, useState } from "react";

// local
import {
  useAppDispatch,
  useAppSelector,
} from "../../../reduxConfig/reduxHooks";
import { setPathState } from "../../../reduxConfig/slices/pathSlice";
import { generateHash } from "../../../helpers/hashHelpers";
import { validate } from "../../../helpers/generalHelpers";
import { UUID } from "../../../types";

// thirdparty
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation } from "react-router";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { raiseAlert } from "../../../helpers/alertHelpers";

interface Props {
  title: string;
}

const LockPath: React.FC<Props> = ({ title }) => {
  // State
  const [lockDialogOpen, setLockDialogOpen] = useState<boolean>(false);
  const [passcodeOpen, setPasscodeOpen] = useState<boolean>(false);
  const [key, setKey] = useState<UUID>(uuidv4());

  // React Router
  const { pathname } = useLocation();

  // Redux
  const dispatch = useAppDispatch();
  const { passcode: hashCode } = useAppSelector((state) => state.path);

  // Helpers
  const handleSubmit = async (values: Record<string, string>) => {
    const { passcode } = values;
    const hashedPasscode = await generateHash(passcode);

    if (hashCode) {
      if (hashedPasscode === hashCode) {
        dispatch(setPathState({ passcode: null, path: null }));
        setLockDialogOpen(false);
        raiseAlert(`You app is now unlocked.`, "success");
      } else {
        raiseAlert("You passcode was incorrect.", "error");
      }
    } else {
      dispatch(setPathState({ path: pathname, passcode: hashedPasscode }));
      setLockDialogOpen(false);
      raiseAlert(`You app is now locked to ${title}.`, "success");
    }
  };

  const generateSchema = () => {
    return Yup.object().shape({
      passcode: Yup.string().nullable().required("You must provide a pin."),
    });
  };

  // Side-Effects
  useEffect(() => {
    if (!lockDialogOpen) {
      setKey(uuidv4());
    }
  }, [lockDialogOpen]);

  return (
    <Box>
      <Tooltip title={hashCode ? "Unlock app" : "Lock app"}>
        <IconButton color="inherit" onClick={() => setLockDialogOpen(true)}>
          {hashCode ? (
            <LockOpenIcon fontSize="large" color="inherit" />
          ) : (
            <LockIcon fontSize="large" color="inherit" />
          )}
        </IconButton>
      </Tooltip>

      <Form
        key={key}
        onSubmit={handleSubmit}
        validate={(values) => validate(values, generateSchema())}
      >
        {({ handleSubmit }) => (
          <Dialog open={lockDialogOpen} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ pb: 0 }}>Lock Screen</DialogTitle>
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent sx={{ pt: 2 }}>
                <Stack spacing={2}>
                  <Typography align="center" variant="body1">
                    Would you like to {hashCode ? "unlock" : "lock"} your screen
                    to {title}?
                  </Typography>
                  <TextField
                    autoFocus
                    name="passcode"
                    label="Pin"
                    variant="standard"
                    type={passcodeOpen ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            title={passcodeOpen ? "Hide pin" : "See pin"}
                          >
                            <IconButton
                              onClick={() => setPasscodeOpen(!passcodeOpen)}
                            >
                              {passcodeOpen ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setLockDialogOpen(false)}>Cancel</Button>
                <Button type="submit">{hashCode ? "Unlock" : "Lock"}</Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Form>
    </Box>
  );
};

export default LockPath;
