// react
import React from "react";

// local
import PasswordField from "../../../public/Shared/Forms/ReactFinalForms/PasswordField";
import UserService from "../../../../services/UserService";
import { validate } from "../../../../helpers/generalHelpers";
import { raiseAlert } from "../../../../helpers/alertHelpers";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";

interface Props {
  showDialog: boolean;
  closeDialog: () => void;
}

const PasswordUpdateForm: React.FC<Props> = ({ closeDialog, showDialog }) => {
  // Helpers
  const generateYupValidation = () => {
    return Yup.object().shape({
      currentPassword: Yup.string().required("This field is required."),
      newPassword: Yup.string()
        .notOneOf(
          [Yup.ref("currentPassword")],
          "Your new password cannot be the same as your current password."
        )
        .required("This field is required."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Your password does not match!")
        .required("This field is required."),
    });
  };

  const submitPasswordToAPI = (values: Record<string, string>) => {
    UserService.getInstance()
      .ChangePassword.update({
        args: [],
        updatedEntity: {
          current_password: values.currentPassword,
          new_password: values.newPassword,
          confirm_password: values.confirmPassword,
        },
      })
      .then(() => {
        raiseAlert("Your password has been successfully updated.", "success");
        closeDialog();
      })
      .catch(() => {
        raiseAlert(
          "There was an issue updating your password. Please try again.",
          "error"
        );
      });
  };

  return (
    <Dialog open={showDialog} maxWidth="sm" fullWidth>
      <Form
        onSubmit={submitPasswordToAPI}
        validate={(values: Record<string, string>) =>
          validate(values, generateYupValidation())
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Grid container rowSpacing={2}>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>Current Password:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="currentPassword"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>New Password:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="newPassword"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>Confirm Password:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="confirmPassword"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDialog()}>Cancel</Button>
              <Button type="submit">Update Password</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default PasswordUpdateForm;
