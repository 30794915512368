// react
import React from "react";

// local
import {
  useAppSelector,
  useIsAuthenticated,
  useRequiresPasswordChange,
} from "../../../reduxConfig/reduxHooks";
import Layout from "../Layout/Layout";
import { raiseAlert, unAuthorizedAlert } from "../../../helpers/alertHelpers";
import { hasPermission } from "../../../hooks/permissionHooks";
import { Permissions } from "../../../enums/permissions";

// thirdparty
import { Navigate, useLocation } from "react-router-dom";

interface Props {
  component: React.ComponentType;
  permissions: Permissions[];
  path?: string;
  title?: string;
}

const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  permissions,
  title,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const requiresPasswordChange = useRequiresPasswordChange();

  // React Router
  const { pathname, state, search } = useLocation();

  // Redux
  const { path: lockPath } = useAppSelector((state) => state.path);

  if (!isAuthenticated) {
    unAuthorizedAlert();
    return <Navigate to="/login" state={{ to: `${pathname}${search}` }} />;
  }

  if (requiresPasswordChange) {
    return (
      <Navigate to="/update-password" state={{ to: `${pathname}${search}` }} />
    );
  }

  if (!hasPermission(permissions)) {
    console.debug("Unauthorized!");
    console.debug(pathname);
    raiseAlert("You are not authorized to visit that page.", "error");
    return <Navigate to="/home" replace={true} />;
  }

  if (lockPath && pathname !== lockPath) {
    return <Navigate to={lockPath} />;
  }

  if (pathname === (state as { to: string } | null)?.to || state === null) {
    return (
      <Layout title={title}>
        <RouteComponent />
      </Layout>
    );
  }

  if ((state as { to: string } | null)?.to) {
    return <Navigate to={(state as { to: string }).to} />;
  }

  return <></>;
};

export default PrivateRoute;
