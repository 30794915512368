// react
import React, { useState, useEffect, useMemo } from "react";

// local
import { User } from "../../../../interfaces/User";
import { UUID } from "../../../../types";
import { validate } from "../../../../helpers/generalHelpers";
import UserService from "../../../../services/UserService";
import NameField from "../../../public/Shared/Forms/ReactFinalForms/NameField";
import EmailField from "../../../public/Shared/Forms/ReactFinalForms/EmailField";
import { raiseAlert } from "../../../../helpers/alertHelpers";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import PasswordField from "../../../public/Shared/Forms/ReactFinalForms/PasswordField";
import HttpStatuses from "../../../../enums/HttpStatuses";
import { URLMethods, URLTypes } from "../../../../services/ModelEndpoint";
import { Select } from "mui-rff";
import { PermissionName } from "../../../../enums/permissions";

interface Props {
  open: boolean;
  closeDialog: () => void;
  selectedUser?: User;
}

interface FormValues {
  name: string;
  email: string;
  role: PermissionName;
  initialPassword: string;
}

const UserFormDialog: React.FC<Props> = ({
  open,
  closeDialog,
  selectedUser,
}) => {
  // State
  const [key, setKey] = useState<UUID>(uuidv4());

  // Refs

  // Helpers
  const submitDataToBackend = (values: FormValues) => {
    if (selectedUser) {
      return UserService.getInstance().User.update<User, User>({
        args: [selectedUser.uuid],
        updatedEntity: {
          ...selectedUser,
          name: values.name,
          email: values.email,
          role: values.role,
        },
      });
    } else {
      return UserService.getInstance().User.create<
        Pick<User, "name" | "email" | "role">,
        User
      >({
        args: [],
        newEntity: {
          name: values.name,
          email: values.email,
          role: values.role,
        },
      });
    }
  };

  const successfullySubmission = () => {
    closeDialog();
    raiseAlert(`User ${!selectedUser ? "created" : "updated"} successfully.`);
  };

  const handleSubmit = (values: FormValues) => {
    submitDataToBackend(values)
      .then((response) => {
        if (response.status === HttpStatuses.HTTP_201_CREATED) {
          UserService.getInstance()
            .User.action({
              args: [response.data.uuid, ""],
              actionURL: "/api/v1/users/:userGuid/override-user-password/",
              urlType: URLTypes.LIST,
              method: URLMethods.PUT,
              submissionData: {
                password: values.initialPassword,
              },
            })
            .then(() => {
              successfullySubmission();
            });
        } else {
          successfullySubmission();
        }
      })
      .catch(() => {
        raiseAlert(
          `There was an issue ${
            !selectedUser ? "creating" : "updating"
          } the user.`,
          "error"
        );
      });
  };

  const generateSchema = () => {
    const defaultValidation = Yup.object().shape({
      name: Yup.string().required("Please enter a name for the user"),
      email: Yup.string()
        .email()
        .required("Please enter an email for the user"),
    });

    if (!selectedUser) {
      return defaultValidation.shape({
        initialPassword: Yup.string().required(
          "Please enter a valid initial password for the new user."
        ),
      });
    }
    return defaultValidation;
  };

  const initialValues = useMemo(() => {
    if (selectedUser) {
      return {
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role,
      };
    } else {
      return {
        name: "",
        email: "",
        role: 0,
      };
    }
  }, [selectedUser]);

  // Side-Effects
  useEffect(() => {
    if (!open) {
      setKey(uuidv4());
    }
  }, [open]);

  return (
    <Form
      key={key}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: FormValues) => validate(values, generateSchema())}
    >
      {({ handleSubmit }) => (
        <Dialog open={open} maxWidth="sm" fullWidth>
          <DialogTitle>{!selectedUser ? "Add User" : "Edit User"}</DialogTitle>
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Stack direction="column" spacing={2}>
                <EmailField disabled={Boolean(selectedUser)} />
                <NameField />
                <Select name="role" variant="standard" label="Role">
                  <MenuItem value={PermissionName.USER}>User</MenuItem>
                  <MenuItem value={PermissionName.ADMIN}>
                    Administrator
                  </MenuItem>
                </Select>
                {!selectedUser ? (
                  <PasswordField
                    name="initialPassword"
                    label="Initial User Password"
                    autoFocus={false}
                  />
                ) : (
                  <></>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button type="submit">
                {!selectedUser ? "Add User" : "Edit User"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Form>
  );
};

export default UserFormDialog;
