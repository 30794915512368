// react
import { useRef, useState } from "react";

// local
import { PermanentTokenService } from "../../../services/PermanentTokenService";
import { PermanentToken } from "../../../interfaces/PermanentToken";
import { PageResponse } from "../../../interfaces/Response";
import PermanentTokenQRCodePreview from "./PermanentTokenQRCodePreview";
import { raiseAlert } from "../../../helpers/alertHelpers";
import ConfirmationDialog from "../../public/Shared/ConfirmationDialog";
import PermanentTokenGenerationFormDialog from "./PermanentTokenGenerationFormDialog";
import { hasPermission } from "../../../hooks/permissionHooks";
import { PermissionName } from "../../../enums/permissions";
import { generatePermanentTokenUrl } from "../../../helpers/permanentTokenUrlHelpers";

// thirdparty
import { Container } from "@mui/material";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

const PermanentTokenList = () => {
  // State
  const [visibleToken, setVisibleToken] = useState<PermanentToken | null>(null);
  const [deleteConfirmationDialogProps, setDeleteConfirmationDialogProps] =
    useState<{
      open: boolean;
      onSubmit: () => void;
      rowData: PermanentToken | null;
    }>({
      open: false,
      onSubmit: () => null,
      rowData: null,
    });
  const [showPermanentTokenConfirmation, setShowPermanentTokenConfirmation] =
    useState<boolean>(false);

  // Refs
  const tableRef = useRef<any>(null);

  // Helpers
  const closeDialog = () => {
    setDeleteConfirmationDialogProps({
      open: false,
      onSubmit: () => null,
      rowData: deleteConfirmationDialogProps.rowData,
    });
  };

  const pullPermanentTokens = (
    query: Query<PermanentToken>
  ): Promise<QueryResult<PermanentToken>> => {
    return new Promise((resolve, reject) => {
      PermanentTokenService.getInstance()
        .Token.list<PageResponse<PermanentToken>>({
          args: [],
          queryParams: {
            page: (query.page + 1).toString(),
            pageSize: query.pageSize.toString(),
            search: query.search,
            ordering: query.orderBy
              ? `${query.orderDirection === "asc" ? "" : "-"}${
                  query.orderBy.field
                }`
              : undefined,
          },
        })
        .then((response) => {
          const { count, results } = response.data;
          resolve({
            data: results,
            page: query.page,
            totalCount: count,
          });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const deletePermanentToken = (rowToDelete: PermanentToken) => {
    PermanentTokenService.getInstance()
      .Token.destroy({ args: [rowToDelete.uuid] })
      .then(() => {
        raiseAlert("The token was deleted successfully.");
        closeDialog();
        tableRef.current?.onQueryChange();
      })
      .catch((err) => {
        console.error(err);
        raiseAlert(
          "There was an issue deleting the token. Please try again.",
          "error"
        );
      });
  };

  // Memoized data
  const defaultActions = [
    {
      icon: ContentCopyIcon,
      tooltip: "Copy token",
      onClick: (e: unknown, rowData: PermanentToken | PermanentToken[]) => {
        raiseAlert("The token has been copied to your clipboard.", "info");
        navigator.clipboard.writeText(
          generatePermanentTokenUrl(
            (rowData as PermanentToken).token
          ).toString()
        );
      },
    },
    {
      icon: VisibilityIcon,
      tooltip: "View QR Code",
      onClick: (e: unknown, rowData: PermanentToken | PermanentToken[]) => {
        setVisibleToken(rowData as PermanentToken);
      },
    },
  ];

  const adminActions = [
    ...defaultActions,
    {
      icon: AddIcon,
      tooltip: "Generate Permanent Token",
      onClick: () => setShowPermanentTokenConfirmation(true),
      isFreeAction: true,
    },
    {
      icon: DeleteIcon,
      tooltip: "Retire Permanent Token",
      onClick: (e: unknown, rowData: PermanentToken | PermanentToken[]) => {
        setDeleteConfirmationDialogProps({
          open: true,
          onSubmit: () => deletePermanentToken(rowData as PermanentToken),
          rowData: rowData as PermanentToken,
        });
      },
    },
  ];

  return (
    <>
      <Container>
        <MaterialTable
          tableRef={tableRef}
          data={pullPermanentTokens}
          columns={[
            {
              title: "Associated User",
              field: "associated_user",
            },
            { title: "Description", field: "description", width: 500 },
            {
              title: "Created",
              field: "created",
              width: 300,
              render: (rowData: PermanentToken) =>
                new Date(rowData.created).toLocaleDateString(),
            },
          ]}
          components={{
            Actions: (props) => {
              const {
                actions,
                components,
                data,
                size,
                disabled,
                forwardedRef,
              } = props;

              if (!actions) {
                return null;
              }
              return (
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  ref={forwardedRef}
                >
                  {actions.map((action: any, index: number) => (
                    <components.Action
                      action={action}
                      key={"action-" + index}
                      data={data}
                      size={size}
                      disabled={disabled}
                    />
                  ))}
                </div>
              );
            },
          }}
          actions={
            hasPermission(PermissionName.ADMIN) ? adminActions : defaultActions
          }
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            pageSize: 10,
            pageSizeOptions: [5, 10],
            showTitle: false,
            paginationType: "stepped",
            searchFieldAlignment: "left",
          }}
        />
      </Container>
      <PermanentTokenQRCodePreview
        tokenToPreview={visibleToken?.token ?? null}
        userName={visibleToken?.associated_user ?? null}
        closeBackdrop={() => setVisibleToken(null)}
      />
      <ConfirmationDialog
        cancelButtonMessage="No"
        open={deleteConfirmationDialogProps.open}
        onClose={closeDialog}
        onSubmit={deleteConfirmationDialogProps?.onSubmit}
        confirmationMessage={`Are you sure you wish to delete ${deleteConfirmationDialogProps.rowData?.associated_user}'s token?`}
        submitButtonText="Yes"
      />
      <PermanentTokenGenerationFormDialog
        open={showPermanentTokenConfirmation}
        onClose={() => {
          setShowPermanentTokenConfirmation(false);
          tableRef.current?.onQueryChange();
        }}
      />
    </>
  );
};

export default PermanentTokenList;
