// react
import React from "react";

// local
import { TimeSubmission } from "../../../../../../interfaces/TimeSubmission";

// thirdparty
import { Box, Stack, Typography } from "@mui/material";

interface Props {
  timeSubmissions: TimeSubmission[];
  weekDay: Date;
}

const DailySummary: React.FC<Props> = ({ timeSubmissions, weekDay }) => {
  if (timeSubmissions.length > 0) {
    return (
      <Box
        sx={{
          height: 300,
        }}
      >
        {timeSubmissions.map((timeSubmission, index) => (
          <Stack key={index} direction="row" spacing={1}>
            <Typography variant="body1">
              {new Date(
                timeSubmission.submission_datetime
              ).toLocaleTimeString()}
            </Typography>
            <Typography variant="body1">
              {timeSubmission.submission_type}
            </Typography>
          </Stack>
        ))}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          height: 300,
        }}
      >
        <Typography align="center" sx={{ pt: 2 }}>
          No submissions found
        </Typography>
      </Box>
    );
  }
};

export default DailySummary;
