// react
import React, { useEffect, useState } from "react";

// local
import QRPreview from "../../../public/Shared/QRPreview";
import { generatePermanentTokenUrl } from "../../../../helpers/permanentTokenUrlHelpers";

// thirdparty
import QRCode from "qrcode";
import { Backdrop, Box, IconButton, Stack } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import jsPDF from "jspdf";

interface Props {
  userName: string | null;
  tokenToPreview: string | null;
  closeBackdrop: () => void;
}

const PermanentTokenQRCodeReview: React.FC<Props> = ({
  userName,
  tokenToPreview,
  closeBackdrop,
}) => {
  // State
  const [qrCodeURL, setQrCodeURL] = useState<string | null>(null);

  useEffect(() => {
    if (tokenToPreview === null) setQrCodeURL(null);
    const url = generatePermanentTokenUrl(tokenToPreview as string);
    setQrCodeURL(url.toString());
  }, [tokenToPreview]);

  const generatePDF = (autoPrint: boolean = false) => {
    if (qrCodeURL === null) return;
    QRCode.toDataURL(qrCodeURL, (err, url) => {
      const pdf = new jsPDF({
        orientation: "portrait",
      });

      const QR_WIDTH = 100;
      const QR_HEIGHT = 100;
      const docWidth = pdf.internal.pageSize.getWidth();

      if (userName) {
        const textWidth = pdf.getTextWidth(userName);
        pdf.text(userName, docWidth / 2 - textWidth / 2, 10);
      }

      pdf.addImage(
        url,
        "png",
        docWidth / 2 - QR_WIDTH / 2,
        12,
        QR_WIDTH,
        QR_HEIGHT
      );
      if (autoPrint) {
        pdf.autoPrint();
      }
      pdf.save("QR Code.pdf");
    });
  };

  return (
    <Backdrop open={Boolean(tokenToPreview)}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            sx={{
              color: "white",
            }}
          >
            <IconButton color="inherit" onClick={() => generatePDF()}>
              <CloudDownloadIcon />
            </IconButton>
            <IconButton color="inherit" onClick={closeBackdrop}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "calc(100vh - 25px)",
            }}
          >
            <QRPreview url={qrCodeURL} width={500} height={500} />
          </Stack>
        </Stack>
      </Box>
    </Backdrop>
  );
};

export default PermanentTokenQRCodeReview;
