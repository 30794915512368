// local
import ModelEndpoint from "./ModelEndpoint";

// thirdparty

export class PermanentTokenService {
  private static _instance: PermanentTokenService;
  public Token = new ModelEndpoint("/api/v1/perm-tokens/");

  public static getInstance() {
    if (!PermanentTokenService._instance) {
      PermanentTokenService._instance = new PermanentTokenService();
    }
    return PermanentTokenService._instance;
  }
}
