import { UUID } from "../types";

export enum SubmissionTypes {
  CHECK_IN = "Check In",
  CHECK_OUT = "Check Out",
}
export interface TimeSubmission {
  uuid: UUID;
  comment: string;
  submission_type: SubmissionTypes;
  submission_datetime: string;
}
