// react

// local
import UserRemoteAutocomplete from "../../../public/Shared/UserRemoteAutocomplete";
import { PermanentTokenService } from "../../../../services/PermanentTokenService";
import { User } from "../../../../interfaces/User";
import { UUID } from "../../../../types";
import { raiseAlert } from "../../../../helpers/alertHelpers";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import { TextField } from "mui-rff";
import * as Yup from "yup";
import { validate } from "../../../../helpers/generalHelpers";

interface Props {
  open: boolean;
  onClose: () => void;
}

const PermanentTokenGenerationFormDialog = ({ open, onClose }: Props) => {
  // Helpers
  const generateSchema = () => {
    return Yup.object().shape({
      associatedUser: Yup.string().uuid().required("You must select a user."),
    });
  };

  const createNewToken = (values: Record<string, string | UUID>) => {
    PermanentTokenService.getInstance()
      .Token.create({
        args: [],
        newEntity: {
          associated_user: values["associatedUser"],
          description: values["description"],
        },
      })
      .then(() => {
        onClose();
        raiseAlert("The new token was successfully created.");
      })
      .catch(() => {
        raiseAlert("There was an issue creating the new token.", "error");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Form
        onSubmit={createNewToken}
        validate={(values: Record<string, string | UUID>) =>
          validate(values, generateSchema())
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Stack spacing={3}>
                <Typography>
                  Select the user you wish to associate with this new token:
                </Typography>
                <Field name="associatedUser">
                  {({ input }) => (
                    <UserRemoteAutocomplete
                      onChange={(_e: unknown, option: User | null) => {
                        input.onChange(option?.uuid ?? null);
                      }}
                    />
                  )}
                </Field>
                <TextField
                  name="description"
                  label="Description"
                  rows={4}
                  variant="standard"
                  multiline
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default PermanentTokenGenerationFormDialog;
