// react
import { useEffect, useState } from "react";

// local

// thirdparty
import QRCode from "qrcode";

interface Props {
  url: string | null;
  width: number;
  height: number;
}

const QRPreview = ({ url, width, height }: Props) => {
  // State
  const [qrURL, setQrURL] = useState<string | null>(null);

  useEffect(() => {
    if (url)
      QRCode.toDataURL(
        url,
        { errorCorrectionLevel: "H" },
        (err, generatedQRURL) => {
          setQrURL(generatedQRURL);
        }
      );
  }, [url]);

  if (qrURL) {
    return <img src={qrURL} width={width} height={height} />;
  }
  return <></>;
};

QRPreview.defaultProps = {
  width: 100,
  height: 100,
};

export default QRPreview;
