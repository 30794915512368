import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { unAuthorizedAlert } from "../helpers/alertHelpers";
import { getCookie } from "../helpers/generalHelpers";
import { store } from "../reduxConfig/reduxStore";
import { forceLogout } from "../reduxConfig/slices/authSlice";

/**
 * Deconstructs browser window location and repairs it back to [site]-url location.
 * @returns string Base Url for API endpoint.
 */
export const parseBaseUrl = (replacementProtocol: any = null) => {
  let [prefix, ...hostDomain] = window.location.hostname.split(".");
  prefix += "-api";
  let baseURL = replacementProtocol ?? window.location.protocol;
  baseURL += "//" + [prefix, ...hostDomain].join(".");
  return baseURL;
};

/**
 * Intercept responses and display a message if 403 unauthorized error.
 *
 * @returns A rejected promise with the error.
 */
const UNAUTHORIZED = 403;
const UNAUTHORIZED_REASON = "Authentication credentials were not provided.";

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/

/**
 * Intercepts requests and resets config headers.
 *
 * @returns Config with new headers and credentials set to true.
 */
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["X-CSRFToken"] = getCookie("csrftoken");
  config.headers["Content-Type"] = "application/json";
  config.withCredentials = true;

  if (process.env.NODE_ENV === "production") {
    // Replace all axios requests to use https instead of http when we are in production
    // This should protect us from mixed-content errors
    config.url = config.url?.replace("http://", "https://");
  }
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const status = error?.response?.status;
  // @ts-expect-error no data
  const reason = error?.response?.data?.detail;

  if (
    error?.response?.config.url !== "/api/v1/auth/check-auth/" &&
    error?.response?.config.url !== "/api/v1/authentication/logout/" &&
    status === UNAUTHORIZED &&
    reason === UNAUTHORIZED_REASON
  ) {
    store.dispatch(forceLogout());
    unAuthorizedAlert();
  }
  return Promise.reject(error);
};

const setupAxiosInterceptors = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};

export default setupAxiosInterceptors;
