// react

// local
import PasswordField from "../../Shared/Forms/ReactFinalForms/PasswordField";
import UserService from "../../../../services/UserService";
import { raiseAlert } from "../../../../helpers/alertHelpers";
import { validate } from "../../../../helpers/generalHelpers";
import {
  useAppDispatch,
  useIsAuthenticated,
  useLoggedInUser,
} from "../../../../reduxConfig/reduxHooks";
import { forceLogout, setUser } from "../../../../reduxConfig/slices/authSlice";

// thirdparty
import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Form } from "react-final-form";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as Yup from "yup";
import { User } from "../../../../interfaces/User";
import { Navigate, useLocation, useNavigate } from "react-router";

const UpdatePassword = () => {
  // React Router
  const { state } = useLocation();
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const loggedInUser = useLoggedInUser();
  const isAuthenticated = useIsAuthenticated();

  if (!loggedInUser || !isAuthenticated) {
    dispatch(forceLogout());
    return <Navigate to="/login" state={state} replace={true} />;
  }

  // Helpers
  const generateYupValidation = () => {
    return Yup.object().shape({
      currentPassword: Yup.string().required("This field is required."),
      newPassword: Yup.string()
        .notOneOf(
          [Yup.ref("currentPassword")],
          "Your new password cannot be the same as your current password."
        )
        .required("This field is required."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Your password does not match!")
        .required("This field is required."),
    });
  };

  const submitPasswordToAPI = (values: Record<string, string>) => {
    UserService.getInstance()
      .ChangePassword.update({
        args: [],
        updatedEntity: {
          current_password: values.currentPassword,
          new_password: values.newPassword,
          confirm_password: values.confirmPassword,
        },
      })
      .then(() => {
        raiseAlert("Your password has been successfully updated.", "success");
        dispatch(
          setUser({
            ...(loggedInUser as User),
            require_password_change: false,
          })
        );
        navigate((state as { to: string | undefined })?.to ?? "/home", {
          replace: true,
        });
      })
      .catch(() => {
        raiseAlert(
          "There was an issue updating your password. Please try again.",
          "error"
        );
      });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 10,
      }}
    >
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Stack spacing={1} sx={{ alignItems: "center" }}>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{ fontWeight: 500, textTransform: "uppercase" }}
              >
                Password Update
              </Typography>
              <AccountCircle
                color="action"
                sx={{
                  fontSize: 250,
                  textAlign: "center",
                }}
              />
            </Stack>
            <Form
              onSubmit={submitPasswordToAPI}
              validate={(values: Record<string, string>) =>
                validate(values, generateYupValidation())
              }
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Stack spacing={2}>
                    <PasswordField
                      name="currentPassword"
                      label="Current Password"
                      autoFocus={false}
                    />
                    <PasswordField
                      name="newPassword"
                      label="New Password"
                      autoFocus={false}
                    />
                    <PasswordField
                      name="confirmPassword"
                      label="Confirm Password"
                      autoFocus={false}
                    />
                    <Button variant="contained" type="submit">
                      Update Password
                    </Button>
                    <Button onClick={() => dispatch(forceLogout())}>
                      Back to Login
                    </Button>
                  </Stack>
                </form>
              )}
            </Form>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UpdatePassword;
