import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import setupAxiosInterceptors from "./configuration/axiosInterceptors";

setupAxiosInterceptors(axios); // this is required now that we're using an index.tsx file

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
