// react
import React from "react";

// local
import PasswordField from "../../../public/Shared/Forms/ReactFinalForms/PasswordField";
import UserService from "../../../../services/UserService";
import { validate } from "../../../../helpers/generalHelpers";
import { raiseAlert } from "../../../../helpers/alertHelpers";

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Form } from "react-final-form";
import * as Yup from "yup";

interface Props {
  showDialog: boolean;
  closeDialog: () => void;
}

const PasscodeUpdateForm: React.FC<Props> = ({ closeDialog, showDialog }) => {
  // Helpers
  const generateYupValidation = () => {
    return Yup.object().shape({
      currentPasscode: Yup.string().required("This field is required."),
      newPasscode: Yup.string()
        .notOneOf(
          [Yup.ref("currentPasscode")],
          "Your new Passcode cannot be the same as your current Passcode."
        )
        .required("This field is required."),
      confirmPasscode: Yup.string()
        .oneOf([Yup.ref("newPasscode")], "Your Passcode does not match!")
        .required("This field is required."),
    });
  };

  const submitPasscodeToAPI = (values: Record<string, string>) => {
    UserService.getInstance()
      .ChangePasscode.update({
        args: [],
        updatedEntity: {
          current_passcode: values.currentPasscode,
          new_passcode: values.newPasscode,
          confirm_passcode: values.confirmPasscode,
        },
      })
      .then(() => {
        raiseAlert("Your passcode has been successfully updated.", "success");
        closeDialog();
      })
      .catch(() => {
        raiseAlert(
          "There was an issue updating your passcode. Please try again.",
          "error"
        );
      });
  };

  return (
    <Dialog open={showDialog} maxWidth="sm" fullWidth>
      <Form
        onSubmit={submitPasscodeToAPI}
        validate={(values: Record<string, string>) =>
          validate(values, generateYupValidation())
        }
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Grid container rowSpacing={2}>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>Current Passcode:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="currentPasscode"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>New Passcode:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="newPasscode"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid item xs={12} sm={4}>
                    <Typography>Confirm Passcode:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <PasswordField
                      name="confirmPasscode"
                      label=""
                      fullWidth
                      hideStartAdornment
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDialog()}>Cancel</Button>
              <Button type="submit">Update Passcode</Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default PasscodeUpdateForm;
