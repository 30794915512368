// local
import { RouteDefinition } from "../interfaces/RouteDefinition";
import SubmitTime from "../components/private/SubmitTime/SubmitTime";
import Users from "../components/private/Users/Users";
import Home from "../components/private/Home/Home";
import { PermissionName } from "../enums/permissions";
import UserWeeklySummary from "../components/private/UserWeeklySummary";
import TokenCreate from "../components/private/SubmitTime/TokenCreate";
import PermanentTokenValidation from "../components/private/PermanentTokenList/PermanentTokenValidation";
import Profile from "../components/private/Profile";
import PermanentTokenList from "../components/private/PermanentTokenList";
import AllowedIPList from "../components/private/AllowedIPList";
import { hasPermission } from "../hooks/permissionHooks";

// thirdparty
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TokenIcon from "@mui/icons-material/Token";
import DnsIcon from "@mui/icons-material/Dns";

export const routes: RouteDefinition[] = [
  {
    title: "Home",
    icon: HomeIcon,
    path: "/home",
    permissions: [PermissionName.USER],
    component: Home,
  },
  {
    title: "Profile",
    hideFromDrawer: true,
    path: "/profile",
    permissions: [PermissionName.USER],
    component: Profile,
  },
  {
    title: "Clock In/Out",
    icon: PunchClockIcon,
    path: "/submit-time",
    permissions: [PermissionName.ADMIN],
    subRoutes: [
      {
        title: "Clock In/Out",
        path: "",
        component: SubmitTime,
        permissions: [PermissionName.ADMIN],
      },
    ],
  },
  {
    title: "Users",
    icon: GroupIcon,
    path: "/users",
    permissions: [PermissionName.ADMIN],
    component: Users,
  },
  {
    title: "Weekly Summary",
    icon: DateRangeIcon,
    path: "/weekly-summary",
    permissions: [PermissionName.ADMIN],
    component: UserWeeklySummary,
  },
  {
    title: "Permanent Token",
    icon: TokenIcon,
    path: "/permanent-token",
    hideFromDrawer: () => !hasPermission(PermissionName.ADMIN),
    permissions: [PermissionName.USER],
    subRoutes: [
      {
        title: "Permanent Tokens",
        permissions: [PermissionName.ADMIN],
        path: "",
        component: PermanentTokenList,
      },
      {
        title: "Validating Token",
        component: PermanentTokenValidation,
        permissions: [PermissionName.USER],
        path: "validate",
      },
    ],
  },
  {
    title: "Submit Time",
    hideFromDrawer: false,
    path: "token-submit",
    permissions: [PermissionName.USER],
    component: TokenCreate,
  },
  {
    component: AllowedIPList,
    icon: DnsIcon,
    path: "/allowed-ips",
    permissions: [PermissionName.ADMIN],
    title: "Allowed IPs",
  },
];
