// react
import { useEffect } from "react";

// local
import { theme } from "./configuration/theme";
import Router from "./components/public/routers/Router";
import { store, persistor } from "./reduxConfig/reduxStore";

// thirdparty
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import AlertSection from "./components/public/Shared/AlertSection";

function App() {
  useEffect(() => {
    axios.options("/api/v1/authentication/login");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router />
          <AlertSection />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
