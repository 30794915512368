// local
import { User } from "../interfaces/User";
import { Response } from "../interfaces/Response";

// thirdparty
import axios from "axios";

export default class AuthService {
  private static _instance: AuthService;

  static getInstance(): AuthService {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService();
    }
    return AuthService._instance;
  }

  public loginWithPassword(
    email: string,
    password: string
  ): Promise<Response<User>> {
    return axios.post("/api/v1/authentication/login/?type=password", {
      email: email,
      password: password,
    });
  }

  public loginWithPasscode(
    email: string,
    passcode: string
  ): Promise<Response<User>> {
    return axios.post("/api/v1/authentication/login/?type=passcode", {
      email: email,
      password: passcode,
    });
  }

  public logout() {
    return axios.patch("/api/v1/authentication/logout/");
  }
}
