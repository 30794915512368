// react
import React from "react";

// local

// thirdparty
import { InputAdornment } from "@mui/material";
import { TextField, TextFieldProps } from "mui-rff";
import PersonIcon from "@mui/icons-material/Person";

interface Props extends TextFieldProps {}

const NameField = (props: Props) => {
  return (
    <TextField
      {...props}
      type="text"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

NameField.defaultProps = {
  name: "name",
  label: "Name",
  variant: "standard",
};

export default NameField;
