export enum AllowedMethods {
  CREATE,
  LIST,
  RETRIEVE,
  UPDATE,
  PARTIAL_UPDATE,
  DELETE,
}

export enum URLMethods {
  GET,
  PUT,
  PATCH,
  DELETE,
  OPTIONS,
}

export enum URLTypes {
  DETAIL,
  LIST,
}
