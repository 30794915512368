// react
import React from "react";

// local

// thirdparty
import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "../../../reduxConfig/reduxHooks";

interface Props {
  path: string;
}

const Redirect: React.FC<Props> = ({ path }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate replace to="/login" state={{ to: location.pathname }} />;
  } else {
    return <Navigate replace to={path} />;
  }
};
export default Redirect;
