// local
import type { RootState, AppDispatch } from "./reduxStore";
import { User } from "../interfaces/User";

// thirdparty
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useIsAuthenticated: () => boolean = () =>
  useAppSelector((state) => state.auth.isAuthenticated);
export const useRequiresPasswordChange: () => boolean = () =>
  Boolean(useAppSelector((state) => state.auth.user?.require_password_change));
export const useLoggedInUser: () => User | null = () =>
  useAppSelector((state) => state.auth.user);
