// react

// local
import { useLoggedInUser } from "../../../reduxConfig/reduxHooks";

// thirdparty
import { Container, Typography } from "@mui/material";
import SubmissionWeeklySummary from "./SubmissionWeeklySummary";

const Home = () => {
  const user = useLoggedInUser();

  return (
    <Container>
      <Typography variant="h4" textAlign="center">
        Welcome {user?.name}!
      </Typography>
      <SubmissionWeeklySummary user={user} />
    </Container>
  );
};

export default Home;
