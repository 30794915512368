// react
import { useEffect, useState, useCallback, useRef } from "react";

// local
import QRPreview from "../../../public/Shared/QRPreview";

// thirdparty
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Skeleton,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import axios from "axios";

const SubmissionTimeQRCode = () => {
  // State
  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [qrDialogVisible, setQrDialogVisible] = useState<boolean>(false);
  const [qrURL, setQRUrl] = useState<string>();

  // Refs
  const intervalIds = useRef<NodeJS.Timeout[]>([]);

  // Helpers
  const generateUrl = (token: string) => {
    const url = new URL(window.location.origin);
    url.pathname = "/submit-time/token-submit";
    url.searchParams.append("token", token);
    return url.toString();
  };

  const pullToken = useCallback(() => {
    axios.get("/api/v1/generate-token/").then((response) => {
      setQRUrl(generateUrl(response.data));
    });
  }, []);

  // Side-Effects
  useEffect(() => {
    pullToken();
    intervalIds.current.push(
      setInterval(() => {
        pullToken();
      }, 180000)
    );

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalIds.current.forEach((intervalId) => {
        clearInterval(intervalId);
      });
    };
  }, [pullToken]);

  if (qrURL) {
    return (
      <>
        <Card
          sx={{ width: 300, textAlign: "center" }}
          onMouseEnter={() => {
            setIconVisible(true);
          }}
          onMouseLeave={() => {
            setIconVisible(false);
          }}
          onClick={() => setQrDialogVisible(true)}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QRPreview url={qrURL} width={250} height={250} />
            <IconButton
              sx={{
                display: iconVisible ? "inherit" : "none",
                position: "absolute",
                transform: "translateX(50%)",
                right: "50%",
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <OpenInFullIcon />
            </IconButton>
          </CardContent>
        </Card>
        <Dialog open={qrDialogVisible} maxWidth="xl">
          <DialogContent sx={{ m: 5 }}>
            <QRPreview url={qrURL} width={600} height={600} />
          </DialogContent>
          <IconButton
            onClick={() => setQrDialogVisible(false)}
            sx={{
              position: "absolute",
              bottom: "94%",
              right: "0%",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey[100],
              },
              zIndex: (theme) => theme.zIndex.modal + 1,
            }}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </Dialog>
      </>
    );
  } else {
    return (
      <Card sx={{ width: 300 }}>
        <CardContent>
          <Skeleton variant="rectangular" width="100%" height={256} />
        </CardContent>
      </Card>
    );
  }
};

export default SubmissionTimeQRCode;
