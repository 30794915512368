// react

// local

// thirdparty
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";

interface Props {
  cancelButtonMessage: string;
  confirmationMessage: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitButtonText: string;
  DialogProps: Omit<DialogProps, "open" | "onClose">;
}

const ConfirmationDialog = ({
  cancelButtonMessage,
  confirmationMessage,
  DialogProps,
  open,
  onClose,
  onSubmit,
  submitButtonText,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} {...DialogProps}>
      <DialogContent>
        <Typography>{confirmationMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelButtonMessage}</Button>
        <Button onClick={onSubmit}>{submitButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmationDialog.defaultProps = {
  cancelButtonMessage: "Cancel",
  confirmationMessage: "Are you sure?",
  submitButtonText: "Submit",
  DialogProps: {
    maxWidth: "xs",
    fullWidth: true,
  },
};

export default ConfirmationDialog;
