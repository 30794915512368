// react
import React, { useState, useMemo } from "react";

// local
import { getNDayOfWeek } from "../../../../../helpers/dateHelpers";

// thirdparty
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

interface Props {
  initialSelection?: Date;
  onChange?: (newDate: Date) => void;
  includeWeekends?: boolean;
}

const WeekPicker: React.FC<Props> = ({
  onChange,
  initialSelection,
  includeWeekends,
}) => {
  // State
  const [value, setValue] = useState<Date>(
    getNDayOfWeek(includeWeekends ? 0 : 1, initialSelection)
  );

  // Memoized data
  const start = useMemo(
    () => getNDayOfWeek(includeWeekends ? 0 : 1, value),
    [value, includeWeekends]
  );
  const end = useMemo(
    () => getNDayOfWeek(includeWeekends ? 6 : 5, value),
    [value, includeWeekends]
  );
  const startInterval = useMemo(
    () => getNDayOfWeek(includeWeekends ? 0 : 1, value),
    [value, includeWeekends]
  );

  const renderWeekPickerDay = (
    date: Date,
    _selectedDates: Array<Date>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const dayIsBetween = isWithinInterval(date, {
      start: startInterval,
      end: end,
    });

    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        disabled={date.getDay() !== (includeWeekends ? 0 : 1)}
        disableHighlightToday
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={value}
        onChange={(newValue) => {
          if (newValue) {
            setValue(new Date(new Date(newValue).setHours(0, 0, 0, 0)));
            onChange?.(new Date(new Date(newValue).setHours(0, 0, 0, 0)));
          }
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date("2022-01-02T04:00:00.000Z")}
      />
    </LocalizationProvider>
  );
};

WeekPicker.defaultProps = {
  initialSelection: new Date(),
};

export default WeekPicker;
