// react
import React, { useState, useEffect } from "react";

// local
import { validate } from "../../../../helpers/generalHelpers";
import { useAppDispatch } from "../../../../reduxConfig/reduxHooks";
import { passwordAuthenticateUser } from "../../../../reduxConfig/thunks/authThunks";

// thirdparty
import {
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { raiseAlert } from "../../../../helpers/alertHelpers";

const Login = () => {
  // State
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  // Redux
  const dispatch = useAppDispatch();

  // React Router
  const navigate = useNavigate();
  const { state } = useLocation();

  // Helpers
  const generateValidation = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    });
  };

  const handleSubmission = (values: Record<string, string>) => {
    dispatch(
      passwordAuthenticateUser({
        email: values.email.toLowerCase(),
        password: values.password,
      })
    )
      .unwrap()
      .then(() => {
        if ((state as { to: string | null })?.to) {
          const { to } = state as { to: string };
          navigate(to, { replace: true });
        } else {
          navigate("/home", { replace: true });
        }
      })
      .catch((error) => {
        raiseAlert("Your username or password is incorrect", "error");
      });
  };

  useEffect(() => {
    document.title = "Login | Time Tracker";
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 10,
      }}
    >
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Stack
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Login
              </Typography>
              <AccountCircle
                color="action"
                sx={{
                  fontSize: 250,
                  textAlign: "center",
                }}
              />
            </Stack>
            <Form
              onSubmit={handleSubmission}
              validate={(values: Record<string, string>) =>
                validate(values, generateValidation())
              }
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Stack spacing={4} sx={{ mb: 2 }}>
                    <TextField
                      name="email"
                      label="Email"
                      variant="standard"
                      type="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: {
                          textTransform: "lowercase",
                        },
                      }}
                    />
                    <TextField
                      name="password"
                      label="Password"
                      variant="standard"
                      type={passwordVisible ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <Tooltip
                              title={
                                passwordVisible
                                  ? "Hide password"
                                  : "See password"
                              }
                            >
                              <IconButton
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              >
                                {passwordVisible ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button variant="contained" type="submit">
                      Login
                    </Button>
                  </Stack>
                </form>
              )}
            </Form>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
