import { getNDayOfWeek } from "../../../../helpers/dateHelpers";

export const generateWeek = (
  initialDate = new Date(),
  startDay = 0,
  endDay = 6
) => {
  return [...Array(endDay + 1).keys()]
    .slice(startDay)
    .map((index) => getNDayOfWeek(index, initialDate));
};
