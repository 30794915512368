export const getNDayOfWeek = (
  dayNumber: number,
  date: Date = new Date()
): Date => {
  const offset = date.getDate() - date.getDay() + dayNumber;

  return new Date(new Date(date.toISOString()).setDate(offset));
};

export const getMondayOfCurrentWeek = () => {
  return getNDayOfWeek(1);
};

export const getFridayOfCurrentWeek = () => {
  return getNDayOfWeek(5);
};

export const msToHMS = (durationMs: number) => {
  var ms = durationMs % 1000;
  durationMs = (durationMs - ms) / 1000;
  var secs = durationMs % 60;
  durationMs = (durationMs - secs) / 60;
  var mins = durationMs % 60;
  var hrs = (durationMs - mins) / 60;

  return `${hrs} hours ${mins} minutes`;
};

export const utcDateTimeFormatter = (date: Date | string) => {
  return new Date(date).toLocaleString().replace(",", "");
};

export const setToPM = (hours: number, inPM = false) => {
  if (inPM && hours < 12) {
    return hours + 12;
  }
  return hours;
};

export const updateDateTimeFromString = (time: string, date = new Date()) => {
  const timeRegex = new RegExp(
    /(?<hours>\d+):(?<minutes>\d+):(?<seconds>\d+) (?<meridiem>[A|P]M)/
  );
  const match = time.match(timeRegex);

  if (match) {
    const { hours, minutes, seconds, meridiem } = match.groups as {
      hours: string;
      minutes: string;
      seconds: string;
      meridiem: string;
    };
    const newDate = new Date(date);
    newDate.setHours(setToPM(parseInt(hours), meridiem === "PM"));
    newDate.setMinutes(parseInt(minutes));
    newDate.setSeconds(parseInt(seconds));
    return newDate;
  }
  return null;
};
