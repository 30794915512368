// react
import { useEffect, useMemo, useRef, useState } from "react";

// local
import { getNDayOfWeek } from "../../../../helpers/dateHelpers";
import ReportService from "../../../../services/ReportService";
import { TimeSubmissionReport } from "../../../../interfaces/TimeSubmissionReport";
import { msToHMS } from "../../../../helpers/dateHelpers";
import DateSelection from "../../../public/Shared/DateSelection";
import WeeklySummary from "../../../public/Shared/WeeklySummary";
import { RowData } from "../../../../interfaces/RowData";
import { PageResponse } from "../../../../interfaces/Response";

// thirdparty
import MaterialTable, {
  Column,
  Query,
  QueryResult,
} from "@material-table/core";
import { Container } from "@mui/material";
import { get } from "lodash";

const WeeklySummaryTable = () => {
  // State
  const [selectedDate, setSelectedDate] = useState<Date>(
    getNDayOfWeek(0, new Date(new Date().setHours(0, 0, 0, 0)))
  );

  const tableRef = useRef<any>();

  // Helpers
  const pullReportData = (
    query: Query<TimeSubmissionReport>
  ): Promise<QueryResult<TimeSubmissionReport>> => {
    return new Promise((resolve, reject) => {
      ReportService.getInstance()
        .TimeSubmission.list<PageResponse<TimeSubmissionReport>>({
          args: [],
          queryParams: {
            page: (query.page + 1).toString(),
            pageSize: query.pageSize.toString(),
            search: query.search,
            date_after: selectedDate.toISOString(),
            date_before: new Date(
              getNDayOfWeek(7, new Date(selectedDate.toISOString())).setHours(
                23,
                59,
                59,
                999
              )
            ).toISOString(),
          },
        })
        .then((response) => {
          const { count, results } = response.data;
          resolve({
            data: results,
            page: query.page,
            totalCount: count,
          });
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const columnDateRender = (rowData: TimeSubmissionReport, field: string) => {
    const pulledData = get(rowData, field);
    if (pulledData > 0) {
      return msToHMS(pulledData);
    }
    return "NA";
  };

  // Memoized data
  const columns: Column<TimeSubmissionReport>[] = useMemo(
    () => [
      { title: "User", field: "name" },
      {
        title: "Sunday",
        field: "sunday",
        render: (rowData) => columnDateRender(rowData, "sunday"),
      },
      {
        title: "Monday",
        field: "monday",
        render: (rowData) => columnDateRender(rowData, "monday"),
      },
      {
        title: "Tuesday",
        field: "tuesday",
        render: (rowData) => columnDateRender(rowData, "tuesday"),
      },
      {
        title: "Wednesday",
        field: "wednesday",
        render: (rowData) => columnDateRender(rowData, "wednesday"),
      },
      {
        title: "Thursday",
        field: "thursday",
        render: (rowData) => columnDateRender(rowData, "thursday"),
      },
      {
        title: "Friday",
        field: "friday",
        render: (rowData) => columnDateRender(rowData, "friday"),
      },
      {
        title: "Saturday",
        field: "saturday",
        render: (rowData) => columnDateRender(rowData, "saturday"),
      },
    ],
    []
  );

  // Side-Effects
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.dataManager.data
        .filter(
          (row: RowData<TimeSubmissionReport>) => row.tableData.showDetailPanel
        )
        .forEach((row: RowData<TimeSubmissionReport>) => {
          tableRef.current.onToggleDetailPanel(
            [row.tableData.id],
            tableRef.current.props.detailPanel
          );
        });

      tableRef.current.onQueryChange();
    }
  }, [selectedDate]);

  return (
    <Container>
      <MaterialTable
        tableRef={tableRef}
        data={pullReportData}
        actions={[
          {
            icon: "",
            isFreeAction: true,
            onClick: () => null,
          },
        ]}
        columns={columns}
        components={{
          Action: () => (
            <DateSelection
              onChange={(newDate) => setSelectedDate(newDate)}
              initialSelection={new Date(selectedDate.toISOString())}
              includeWeekends
            />
          ),
        }}
        options={{
          showTitle: false,
          searchFieldAlignment: "left",
          sorting: false,
          pageSize: 10,
          pageSizeOptions: [5, 10],
          padding: "dense",
          paginationType: "stepped",
        }}
        detailPanel={({ rowData }) => (
          <Container>
            <WeeklySummary
              userGuid={rowData.uuid}
              selectedDate={selectedDate}
              refresh={() => tableRef.current.onQueryChange()}
            />
          </Container>
        )}
      />
    </Container>
  );
};

export default WeeklySummaryTable;
