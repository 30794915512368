// react
import React from "react";

// local
import { User } from "../../../../interfaces/User";

// thirdparty
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface Props {
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
  chunkedData: User[][];
}

const GridCell: React.FC<Props> = ({
  columnIndex,
  rowIndex,
  chunkedData,
  setSelectedUser,
  style,
}) => {
  const currentData = chunkedData?.[rowIndex]?.[columnIndex];

  if (currentData) {
    return (
      <div style={style}>
        <Stack sx={{ alignItems: "center" }}>
          <Card sx={{ width: 350 }}>
            <CardActionArea onClick={() => setSelectedUser(currentData)}>
              <CardContent sx={{ textAlign: "center" }}>
                <AccountCircleIcon
                  color="action"
                  sx={{
                    fontSize: 150,
                  }}
                />
                <Typography variant="h4">{currentData.name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </div>
    );
  } else {
    return <></>;
  }
};

export default GridCell;
