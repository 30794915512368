// react
import { useEffect, useState } from "react";

// local
import { raiseAlert } from "../../../../helpers/alertHelpers";
import { PermanentTokenService } from "../../../../services/PermanentTokenService";
import { URLMethods } from "../../../../services/ModelEndpoint";
import { TokenValidationStatuses } from "./enum";
import HttpStatuses from "../../../../enums/HttpStatuses";

// thirdparty
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";

const PermanentTokenValidation = () => {
  // State
  const [tokenValidationStatus, setTokenValidationStatus] =
    useState<TokenValidationStatuses>(TokenValidationStatuses.NOT_VALIDATED);

  // React Router
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  // Helper
  const determineContent = () => {
    switch (tokenValidationStatus) {
      case TokenValidationStatuses.NOT_VALIDATED:
      case TokenValidationStatuses.VALIDATING:
        return <CircularProgress />;
      case TokenValidationStatuses.INVALID:
        return (
          <Stack spacing={2} alignItems="center">
            <Typography>The provided token is invalid.</Typography>
            <Link to="/home">Back to Home</Link>
          </Stack>
        );
      case TokenValidationStatuses.UNAUTHORIZED:
        return (
          <Stack spacing={2} alignItems="center">
            <Typography>
              You are not authorized to use the provided token.
            </Typography>
            <Link to="/home">Back to Home</Link>
          </Stack>
        );
      case TokenValidationStatuses.DISALLOWED_IP:
        return (
          <Stack spacing={2} alignItems="center">
            <Typography>
              You are not allowed to check-in from your current location.
            </Typography>
            <Link to="/home">Back to Home</Link>
          </Stack>
        );
      default:
        return <></>;
    }
  };

  const validateToken = () => {
    PermanentTokenService.getInstance()
      .Token.action<unknown, { token: string }>({
        actionURL: "/api/v1/perm-tokens/validate/",
        args: [],
        method: URLMethods.PATCH,
        queryParams: {
          token: queryParams.get("token")!,
        },
      })
      .then((response) => {
        navigate(`/token-submit?token=${response.data}`, {
          replace: true,
        });
      })
      .catch((error) => {
        if (
          error.response.status === HttpStatuses.HTTP_403_FORBIDDEN &&
          error.response.data ===
            "The requesting user is not authorized to use the provided token."
        ) {
          setTokenValidationStatus(TokenValidationStatuses.UNAUTHORIZED);
        } else if (
          error.response.status === HttpStatuses.HTTP_403_FORBIDDEN &&
          error.response.data === "IP address is not allowed."
        ) {
          setTokenValidationStatus(TokenValidationStatuses.DISALLOWED_IP);
        } else {
          setTokenValidationStatus(TokenValidationStatuses.INVALID);
        }
      });
  };

  // Side-Effects
  useEffect(() => {
    if (!queryParams.get("token")) {
      raiseAlert("No token provided.", "error");
      navigate("/home", { replace: true });
    } else {
      setTokenValidationStatus(TokenValidationStatuses.VALIDATING);
      validateToken();
    }
  }, []);

  return (
    <Container sx={{ textAlign: "center" }}>{determineContent()}</Container>
  );
};

export default PermanentTokenValidation;
