// local
import AuthService from "../../services/AuthService";

// thirdparty
import { createAsyncThunk } from "@reduxjs/toolkit";

export const passwordAuthenticateUser = createAsyncThunk(
  "auth/passwordAuthenticate",
  async (
    { email, password }: { email: string; password: string },
    thunkApi
  ) => {
    try {
      return await AuthService.getInstance().loginWithPassword(email, password);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const passcodeAuthenticateUser = createAsyncThunk(
  "auth/passcodeAuthenticate",
  async (
    { email, passcode }: { email: string; passcode: string },
    thunkApi
  ) => {
    try {
      return await AuthService.getInstance().loginWithPasscode(email, passcode);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkApi) => {
  try {
    return await AuthService.getInstance().logout();
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
