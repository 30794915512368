// Function to generate a hash from a string
// The algorithm used can be specified, or will default to SHA-512
export const generateHash = (str: string, algorithm = "SHA-512") => {
  // Create an array buffer for the supplied string - this buffer contains an integer representation of the string which can be used to generate the hash
  let strBuffer = new TextEncoder().encode(str);

  // use SubtleCrypto to generate the hash using the specified algorithm
  return crypto.subtle.digest(algorithm, strBuffer).then((hash) => {
    // The resulting hash is an arrayBuffer, and should be converted to its hexadecimal representation

    // Initialize the result as an empty string - the hexadecimal characters for the values in the array buffer will be appended to it
    let result = "";

    // The DataView view provides an interface for reading number types from the ArrayBuffer
    const view = new DataView(hash);

    // Iterate over each value in the arrayBuffer and append the converted hexadecimal value to the result
    for (let i = 0; i < hash.byteLength; i += 4) {
      result += ("00000000" + view.getUint32(i).toString(16)).slice(-8);
    }

    return result;
  });
};
